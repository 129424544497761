import {Injectable} from '@angular/core';
import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Observable} from 'rxjs';
import {AuthService} from '../services/auth/auth.service';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {
  constructor(private _authService: AuthService) {
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (
      request.url.includes('auth')
    ) {
      return next.handle(request);
    } else {
      // add token
      let newRequest = request.clone({
        withCredentials: true,
        setHeaders: {
          Authorization: `Bearer ${this._authService.accessToken()}`,
        }
      });
      return next.handle(newRequest);
    }
  }
}
