import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {Project} from 'app/core/services/project/project';
import {BehaviorSubject, Observable, of, Subject} from "rxjs";
import {debounceTime, delay, switchMap, tap} from "rxjs/operators";
import {Router} from "@angular/router";
import {AccountActionEvent} from 'app/modules/project';
import {Project_status, ProjectStatusList} from "../../models/Roles";

interface projectSearchResult {
  projects: Project[];
  total: number;
}

function matchesStatus(item: Project, status: string) {
  return (status == null || status === 'ALL') ? true : (item.status === status);
}


interface State {
  page: number;
  pageSize: number;
  searchTerm: string;
  sortColumn: string;
  statusSearchTerm: string;
  startIndex: number;
  endIndex: number;
  totalRecords: number;
}

function matches(project: Project, term: string) {
  return (
    project.name.toLowerCase().includes(term) ||
    project.description.toLowerCase().includes(term)
  );
}

const compare = (v1: string, v2: string) => (v1 < v2 ? -1 : v1 > v2 ? 1 : 0);

@Component({
  selector: 'project-table',
  templateUrl: './project-table.component.html',
  styleUrls: ['./project-table.component.scss']
})
export class ProjectTableComponent implements OnInit, OnChanges {
  readonly Project_status = Project_status;
  private _State: State;
  private _loading$ = new BehaviorSubject<boolean>(true);
  private _search$ = new Subject<void>();
  private _totalProjects$ = new BehaviorSubject<number>(0);
  public projects$: BehaviorSubject<Project[]> = new BehaviorSubject<Project[]>([]);
  @Input() projectList: Project[];
  @Input() disableAllowed: boolean;
  @Output() editProject: EventEmitter<string> = new EventEmitter<string>();
  @Output() accountAction: EventEmitter<AccountActionEvent> = new EventEmitter<AccountActionEvent>();
  projectStatus = ProjectStatusList;

  constructor(private _router: Router) {
  }

  ngOnChanges(changes: SimpleChanges): void {
    this._search$.next();
  }


  ngOnInit(): void {

    this._State = {
      page: 1,
      pageSize: 10,
      searchTerm: "",
      statusSearchTerm: "ALL",
      sortColumn: "",
      startIndex: 0,
      endIndex: 9,
      totalRecords: 0,
    };
    this._search$
      .pipe(
        tap(() => this._loading$.next(true)),
        debounceTime(100),
        switchMap(() => this._search()),
        delay(100),
        tap(() => this._loading$.next(false))
      )
      .subscribe((result) => {
        this.projects$.next(result.projects);
        this._totalProjects$.next(result.total);
      });

    this._search$.next();
  }


  set pageSize(pageSize: number) {
    this._set({pageSize});
  }


  set searchTerm(searchTerm: string) {
    this._set({searchTerm});
    this._search$.next();
  }

  set statusSearchTerm(statusSearchTerm: string) {
    this._set({statusSearchTerm});
    this._search$.next();
  }

  private _set(patch: Partial<State>) {
    Object.assign(this._State, patch);
    this._search$.next();
  }

  get startIndex() {
    return this._State.startIndex;
  }

  get total$() {
    return this._totalProjects$.asObservable();
  }

  get endIndex() {
    return this._State.endIndex;
  }

  get totalRecords() {
    return this._State.totalRecords;
  }

  get page() {
    return this._State.page;
  }

  get pageSize() {
    return this._State.pageSize;
  }

  get searchTerm() {
    return this._State.searchTerm;
  }


  get statusSearchTerm() {
    return this._State.statusSearchTerm;
  }

  set page(page: number) {
    this._set({page});
  }

  _search(): Observable<projectSearchResult> {
    const {page, searchTerm, statusSearchTerm} =
      this._State;
    // 1. sort
    let projects = this.projectList;

    // 2. filter
    projects = projects.filter((project) => matches(project, searchTerm));
    projects = projects.filter((project) => matchesStatus(project, statusSearchTerm));
    const total = projects.length;
    // 3. paginate
    this._State.totalRecords = projects.length;
    this._State.startIndex = (page - 1) * this.pageSize + 1;
    this._State.endIndex = (page - 1) * this.pageSize + this.pageSize;

    if (this.endIndex > this.totalRecords) {
      this._State.endIndex = this.totalRecords;
    }
    projects = projects.slice(this._State.startIndex - 1, this._State.endIndex);

    return of({projects, total});
  }

  onEdit(userId: string) {
    this.editProject.emit(userId);
  }

  onAccountAction(uuid: string, action: "enable" | "disable" | "delete" | "reset") {
    this.accountAction.emit({
      action: action,
      id: uuid
    });
  }


  onDetail(uuid: string) {
    this._router.navigateByUrl(`/projects/details?uuid=${uuid}`);

  }

}
