<div class="row mb-md-2   ">
  <div class="col-sm-12 col-md-3">
    <div class="dataTables_length">
      <label class="d-inline-flex align-items-center">Show
        <select [(ngModel)]="pageSize" aria-controls="tickets-table" class="form-control form-control-sm mx-2"
                name="tickets-table_length"
                name="pageSize">
          <option [ngValue]="10">10</option>
          <option [ngValue]="25">25</option>
          <option [ngValue]="50">50</option>
        </select> entries</label>
    </div>
  </div>
  <div class="col-md-9 row  col-sm-12 d-flex justify-content-end">
    <div class="mb-3 col-md-4 col-sm-12  ">
      <label><i class="bx bx-filter-alt text-primary"></i> Status</label>
      <ng-select [(ngModel)]="statusSearchTerm" [clearable]="true" [items]="projectStatus" bindLabel="name"
                 bindValue="name" class="edit-input" placeholder="All">
      </ng-select>
    </div>
    <!-- Search -->
    <div class="col-sm-12 col-md-4 ">

      <label>Search</label>
      <input [(ngModel)]="searchTerm" aria-controls="tickets-table" class="form-control form-control " name="searchTerm"
             type="text">

    </div>
  </div>

  <!-- End search -->
</div>
<!-- Table -->
<div class="table-responsive">
  <table class="table table-sm table-hover">
    <thead>
    <tr>
      <th style="width: 30%">Name</th>
      <th>Start Date</th>
      <th>End Date</th>
      <th>Total Budget</th>
      <th>Status</th>
      <th style="width: 20%;">Actions</th>
    </tr>
    </thead>
    <tbody *ngFor="let project of projects$ | async ;let i=index">

    <tr class="date-cursor" (click)="onDetail(project.uuid)">

      <td style="text-align: justify" class="align-middle  font-weight-semibold ">
        <ngb-highlight [result]="project.name" [result]="project.name" [term]="searchTerm">
        </ngb-highlight>
      </td>

      <td class="align-middle text-truncate ">
        <ngb-highlight [result]="project.startDate" [term]="searchTerm">
        </ngb-highlight>
      </td>

      <td class="align-middle text-truncate ">
        <ngb-highlight [result]="project.endDate" [term]="searchTerm">
        </ngb-highlight>
      </td>

      <td class="align-middle text-truncate ">
        {{project.totalBudget | currency:''}}
      </td>

      <td [ngClass]="{'bg-light':project.status===Project_status.NOT_STARTED,
             'bg-self':project.status===Project_status.IN_PROGRESS,
             'bg-accepted':project.status===Project_status.COMPLETED,
              'bg-eval':project.status===Project_status.MOVED,
              'bg-comp-rejected':project.status===Project_status.CANCELED,
              'bg-manager':project.status===Project_status.SUSPENDED
         }">
        {{project.status}}

      </td>

      <td (click)="$event.stopPropagation()" class="align-middle">
        <div class="btn-group">
          <button (click)="onEdit(project.uuid)" class="btn btn-sm btn-outline-primary ">
            <i class="mdi mdi-pen "></i>
            Edit
          </button>

          <button (click)="onDetail(project.uuid)" class="btn btn-sm btn-outline-info ">
            <i class="mdi mdi-list-status "></i>
            Details
          </button>

          <button (click)="onAccountAction(project.uuid, 'delete')"
                  class="btn btn-sm btn-outline-danger ">
            <i class="mdi mdi-trash-can-outline "></i>
            Delete
          </button>
        </div>
      </td>

    </tr>


    </tbody>
  </table>
</div>
<!-- End table -->

<div class="row justify-content-md-between align-items-md-center mt-2">
  <div class="col-sm-12 col-md-5">
    <div aria-live="polite" class="dataTables_info mb-2" id="tickets-table_info" role="status">Showing
      {{startIndex}} to
      {{endIndex}} of {{totalRecords}}
      entries
    </div>
  </div>
  <!-- Pagination -->
  <div class="col-sm-12 col-md-5">
    <div class="text-md-right float-md-end pagination-rounded">
      <ngb-pagination [(page)]="page" [collectionSize]="total$ | async" [pageSize]="pageSize">
      </ngb-pagination>
    </div>
  </div>

  <!-- End Pagination -->
</div>
