import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {environment} from 'environments/environment';
import {Project} from './project';
import {Utilization} from "../../../modules/project/project-detail/budget";
import {enc, HmacSHA256} from 'crypto-js';
import {DomSanitizer} from "@angular/platform-browser";

@Injectable({
  providedIn: 'root'
})
export class ProjectService {

  constructor(private _httpClient: HttpClient, private sanitizer: DomSanitizer,) {
  }

  getAll(): Observable<any> {

    return this._httpClient
      .get(environment.baseURL + '/project');
  }

  add(project: Project): Observable<any> {
    return this._httpClient
      .post(environment.baseURL + '/project/create', project);
  }

  uploadSurvey(file, projectUUID: string): Observable<any> {
    return this._httpClient
      .post(environment.baseURL + `/project/${projectUUID}/survey/upload`, file);
  }

  addIndicator(indicator: any, projectUUID: string): Observable<any> {
    return this._httpClient
      .post(environment.baseURL + `/project/${projectUUID}/indicators/create`, indicator);
  }

  updateIndicator(indicator: any, projectUUID: string, uuid: string): Observable<any> {
    return this._httpClient
      .put(environment.baseURL + `/project/${projectUUID}/indicators/update/${uuid}`, indicator);
  }

  deleteIndicator(indicator: any, projectUUID: string, uuid: string): Observable<any> {
    return this._httpClient
      .post(environment.baseURL + `/project/${projectUUID}/indicators/delete/${uuid}`, indicator, {
        responseType: "text"
      });
  }

  getById(uuid: string): Observable<any> {
    return this._httpClient
      .get(environment.baseURL + `/project/get/${uuid}`);
  }

  delete(userID: string): Observable<string> {
    return this._httpClient
      .post(environment.baseURL + `/project/delete/${userID}`, '', {responseType: "text"});
  }

  update(project: Project, uuid: string): Observable<any> {
    return this._httpClient
      .put(environment.baseURL + `/project/update/${uuid}`, project);

  }

  getLogic(projectUUID: string, type: string) {
    return this._httpClient
      .get(environment.baseURL + `/project/${projectUUID}/interventionlogic/${type}`)
  }

  getBudgets(projectUUID: string, type: string) {
    return this._httpClient
      .get(environment.baseURL + `/project/${projectUUID}/budget/${type}`)
  }

  getSubLogics(projectUUID: string, type: string, uuid: string) {
    return this._httpClient
      .get(environment.baseURL + `/project/${projectUUID}/interventionlogic/${type}/get/${uuid}/sublogics`)
  }

  updateLogic(projectUUID: string, type: string, uuid: string, data: any) {
    return this._httpClient
      .put(environment.baseURL + `/project/${projectUUID}/interventionlogic/${type}/update/${uuid}`, data)
  }

  deleteLogic(projectUUID: string, type: string, uuid: string) {
    return this._httpClient
      .post(environment.baseURL + `/project/${projectUUID}/interventionlogic/${type}/delete/${uuid}`, {}, {responseType: "text"})
  }

  addLogic(projectUUID: string, type: string, data: any) {
    return this._httpClient
      .post(environment.baseURL + `/project/${projectUUID}/interventionlogic/${type}/create`, data)
  }

  addBudget(projectUUID: string, data: any) {
    return this._httpClient
      .post(environment.baseURL + `/project/${projectUUID}/budget/create`, data)
  }

  addUtilization(projectUUID: string, data: any) {
    return this._httpClient
      .post(environment.baseURL + `/project/${projectUUID}/utilization/create`, data)
  }

  getBudgetUtilization(projectUUID: string, budgetUUID: any) {
    return this._httpClient
      .get<Utilization[]>(environment.baseURL + `/project/${projectUUID}/utilization/budget/${budgetUUID}`)
  }

  addExtension(projectUUID: string, data: any) {
    return this._httpClient
      .post(environment.baseURL + `/project/extend/${projectUUID}`, data)
  }

  updateUtilization(projectUUID: string, uuid: string, data: any) {
    return this._httpClient
      .put(environment.baseURL + `/project/${projectUUID}/utilization/update/${uuid}`, data)
  }

  updateBudget(projectUUID: string, uuid: string, data: any) {
    return this._httpClient
      .put(environment.baseURL + `/project/${projectUUID}/budget/update/${uuid}`, data)
  }

  deleteBudget(projectUUID: string, uuid: string) {
    return this._httpClient
      .post(environment.baseURL + `/project/${projectUUID}/budget/delete/${uuid}`, {}, {
        responseType: "text"
      })
  }

  deleteUtilization(projectUUID: string, uuid: string) {
    return this._httpClient
      .post(environment.baseURL + `/project/${projectUUID}/utilization/delete/${uuid}`, {}, {
        responseType: "text"
      })
  }

  generateToken() {
    const header = {
      alg: 'HS256', // Algorithm used for signing the token
      typ: 'JWT' // Token type
    };

    const payload = {
      resource: {dashboard: 2},
      params: {},
      exp: Math.round(Date.now() / 1000) + (10 * 60) // 10-minute expiration
    };
    const encodedHeader = this.base64UrlEncode(JSON.stringify(header));
    const encodedPayload = this.base64UrlEncode(JSON.stringify(payload));
    const signature = this.generateSignature(encodedHeader + '.' + encodedPayload, environment.METABASE_SECRET_KEY);
    const token = encodedHeader + '.' + encodedPayload + '.' + signature;
    const url = `${environment.METABASE_SITE_URL}/embed/dashboard/${token}#bordered=true&titled=true`;
    return url;
    return this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }

  base64UrlEncode(str: string): string {
    let base64 = btoa(str);
    base64 = base64.replace(/\+/g, '-').replace(/\//g, '_').replace(/=+$/, '');
    return base64;
  }

  generateSignature(data: string, secretKey: string): string {
    const signature = HmacSHA256(data, secretKey);
    return this.base64UrlEncode(signature.toString(enc.Base64));
  }

}
