import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {NgbActiveModal} from "@ng-bootstrap/ng-bootstrap";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {ProjectStatusList} from "../../../shared/models/Roles";
import {ProjectService} from "../../../core/services/project/project.service";
import {LoaderService} from "../../../core/services/shared/loader.service";
import {InterventionLogic} from "../../../core/services/project/project";

@Component({
  selector: 'app-intervention-logic-form',
  templateUrl: './intervention-logic-form.component.html',
  styleUrls: ['./intervention-logic-form.component.scss']
})
export class InterventionLogicFormComponent implements OnInit {
  @Input() modalTitle: string;
  @Input() logicType: string;
  @Input() projectUUId: string;
  @Input() data: any;
  formChanged: boolean = false;
  @Input() isUpdate: boolean = false;
  @Input() parentList: InterventionLogic[];
  @Input() hasParent: boolean = false;
  @Input() parentLabel: string;
  @Output() dataChanged: EventEmitter<void> = new EventEmitter<void>();
  @Output() error_event: EventEmitter<any> = new EventEmitter<any>();
  logicForm: FormGroup;
  formInitialValue: any;
  saving: boolean = false;

  constructor(public activeModal: NgbActiveModal,
              private fb: FormBuilder,
              private _loaderService: LoaderService,
              private projectService: ProjectService) {
  }

  ngOnInit(): void {

    this.logicForm = this.fb.group({
      code: [null, Validators.required],
      name: [null, Validators.required],
      parentUuid: [null]
    })
    //subscribe to form change
    this.logicForm.valueChanges.subscribe((value) => {
      this.formChanged = this.formInitialValue ? Object.keys(this.formInitialValue)
        .some(key => this.logicForm.value[key] !==
          this.formInitialValue[key]) : true;
    });

    if (this.isUpdate) {
      this.logicForm.patchValue(this.data);
    }
  }

  get form() {
    return this.logicForm.controls;
  }

  protected readonly ProjectStatusList = ProjectStatusList;
  submit: boolean = false;

  validSubmit() {
    this.submit = true;
    if (this.logicForm.valid) {
      this.saving = true;
      this.isUpdate ? this.updateLogic() : this.addLogic();
    }
  }

  updateLogic() {
    this._loaderService.showProgress.next(true);
    this.projectService.updateLogic(this.projectUUId, this.logicType, this.data.uuid, this.logicForm.value).subscribe(
      () => {
        this._loaderService.showProgress.next(false);
        this.dataChanged.emit();
      },
      (error) => {
        console.log(error);
        this.saving = false;
        this.error_event.emit(error);
        this._loaderService.showProgress.next(false);

      }
    );

  }

  addLogic() {
    this._loaderService.showProgress.next(true);
    this.projectService.addLogic(this.projectUUId, this.logicType, this.logicForm.value).subscribe(
      () => {
        this._loaderService.showProgress.next(false);
        this.dataChanged.emit();
      },
      (error) => {
        this.saving = false;
        this.error_event.emit(error);
        this._loaderService.showProgress.next(false);

      }
    );

  }
}
