import {Component, Input, OnInit, SimpleChanges} from '@angular/core';
import {Outcome} from "../project-detail/outcome";
import {BehaviorSubject, Observable, of, Subject} from "rxjs";
import {debounceTime, delay, switchMap} from "rxjs/operators";

interface outcomeSearchResult {
  outcomes: Outcome[];
  total: number;
}

interface State {
  page: number;
  pageSize: number;
  searchTerm: string;
  sortColumn: string;
  statusSearchTerm: boolean;
  startIndex: number;
  endIndex: number;
  totalRecords: number;
}

@Component({
  selector: 'app-project-outcome',
  templateUrl: './project-outcome.component.html',
  styleUrls: ['./project-outcome.component.scss']
})
export class ProjectOutcomeComponent implements OnInit {

  public outcomes$ = new BehaviorSubject<Outcome[]>([]);

  @Input() outcomes: Outcome[];
  private _State: State;
  private _search$ = new Subject<void>();


  constructor() {
  }

  ngOnInit(): void {

    this._State = {
      page: 1,
      pageSize: 5,
      searchTerm: "",
      statusSearchTerm: true,
      sortColumn: "",
      startIndex: 0,
      endIndex: 5,
      totalRecords: 0,
    };
    this._search$
      .pipe(
        debounceTime(100),
        switchMap(() => this._search()),
        delay(100),
      )
      .subscribe((result) => {
        this.outcomes$.next(result.outcomes);
      });

    this._search$.next();
  }


  set pageSize(pageSize: number) {
    this._set({pageSize});
  }


  set searchTerm(searchTerm: string) {
    this._set({searchTerm});
    this._search$.next();
  }

  set statusSearchTerm(statusSearchTerm: boolean) {
    this._set({statusSearchTerm});
    this._search$.next();
  }

  private _set(patch: Partial<State>) {
    Object.assign(this._State, patch);
    this._search$.next();
  }

  get startIndex() {
    return this._State.startIndex;
  }

  get total$() {
    return this._State.totalRecords;
  }

  get endIndex() {
    return this._State.endIndex;
  }

  get totalRecords() {
    return this._State.totalRecords;
  }

  get page() {
    return this._State.page;
  }

  get pageSize() {
    return this._State.pageSize;
  }

  get searchTerm() {
    return this._State.searchTerm;
  }


  get statusSearchTerm() {
    return this._State.statusSearchTerm;
  }

  set page(page: number) {
    this._set({page});
  }

  _search(): Observable<outcomeSearchResult> {

    const {page, searchTerm, statusSearchTerm} =
      this._State;
    // 1. sort
    let outcomes = this.outcomes;
    const total = outcomes.length;
    // 3. paginate
    this._State.totalRecords = outcomes.length;
    this._State.startIndex = (page - 1) * this.pageSize + 1;
    this._State.endIndex = (page - 1) * this.pageSize + this.pageSize;
    if (this.endIndex > this.totalRecords) {
      this._State.endIndex = this.totalRecords;
    }
    outcomes = outcomes.slice(this._State.startIndex - 1, this._State.endIndex);

    return of({outcomes, total});
  }

  onEdit(uuid: string) {

  }

  onDelete(uuid: string, delete1: string) {

  }

  ngOnChanges(changes: SimpleChanges): void {
    this._search$.next();
  }

  onAddOutcome() {

  }


}
