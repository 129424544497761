import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {AuthService} from 'app/core/services/auth/auth.service';
import {RoleBasedRoutingService} from 'app/core/services/auth/role-based-routing.service';

@Component({
  selector: 'app-page401',
  templateUrl: './page401.component.html',
  styleUrls: ['./page401.component.scss']
})

/**
 * Pages-401 component
 */
export class Page401Component implements OnInit {

  constructor(private authService: AuthService, private router: Router,
              private _userLandingService: RoleBasedRoutingService) {
  }

  ngOnInit(): void {
  }

  navigateToDashboard() {
    this.authService.signOut()
      .subscribe(() => {
        this.router.navigate(['/account/login']);
      })

  }

}
