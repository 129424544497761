
<div class="mt-3 col-sm-8">
  <div class="mb-3">
    <button (click)="onAddOutcome()" class="btn btn-sm btn-outline-primary">Add Outcome</button>
  </div>

  <div class="table-responsive ">
    <table class="table table-sm table-hover">
      <thead>
      <tr>
        <th >Name</th>
        <th >Type</th>
        <th >measurement</th>
        <th >status</th>
        <th style="width: 20%;">Actions</th>
      </tr>
      </thead>
      <tbody *ngFor="let outcome of outcomes$ | async ;let i=index">
      <tr>

        <td class="align-middle text-truncate ">
          {{
          outcome.name
          }}
        </td>

        <td class="align-middle text-truncate ">
         {{outcome.type}}
        </td>


        <td class="align-middle text-truncate ">
          {{outcome.measurement}}
        </td>

        <td class="align-middle text-truncate ">

        <span class="badge badge-soft-primary" *ngIf="outcome.status === 0">
          INACTIVE
        </span>
          <span class="badge badge-soft-success" *ngIf="outcome.status == 1">
          ACTIVE
        </span>
          <span class="badge badge-soft-danger" *ngIf="outcome.status == 2">
          CANCELED
        </span>
        </td>


        <td (click)="$event.stopPropagation()" class="align-middle">
          <div class="btn-group">
            <button (click)="onEdit(outcome.uuid)" class="btn btn-sm btn-outline-primary ">
              <i class="mdi mdi-pen "></i>
              Edit
            </button>

            <button (click)="onDelete(outcome.uuid, 'delete')"
                    class="btn btn-sm btn-outline-danger ">
              <i class="mdi mdi-trash-can-outline "></i>
              Delete
            </button>
          </div>
        </td>
      </tr>


      </tbody>
    </table>
  </div>
  <!-- End table -->
  <div class="row justify-content-md-between align-items-md-center mt-2">
    <div class="col-sm-12 col-md-5">
      <div aria-live="polite" class="dataTables_info mb-2" id="tickets-table_info" role="status">Showing
        {{startIndex}} to
        {{endIndex}} of {{totalRecords}}
        entries
      </div>
    </div>
    <!-- Pagination -->
    <div class="col-sm-12 col-md-5">
      <div class="text-md-right float-md-end pagination-rounded">
        <ngb-pagination [(page)]="page" [collectionSize]="totalRecords" [pageSize]="pageSize">
        </ngb-pagination>
      </div>
    </div>
    <!-- End Pagination -->
  </div>
</div>
