export const RolesEnum = Object.freeze({
  SUPER_ADMIN: "SUPER_ADMIN",
  PROGRAM_ADMIN: "PROGRAM_ADMIN",
  PROJECT_ADMIN: "PROJECT_ADMIN",
  REGION_ADMIN: "REGION_ADMIN",
  ZONE_ADMIN: "ZONE_ADMIN",
  HR_MANAGER: "HR_MANAGER",
  EMPLOYEE: "EMPLOYEE"
})
export const Project_status = Object.freeze({
  NOT_STARTED: "NOT_STARTED",
  IN_PROGRESS: "IN_PROGRESS",
  COMPLETED: "COMPLETED",
  SUSPENDED: "SUSPENDED",
  MOVED: "MOVED",
  CANCELED: "CANCELED",
  ALL: "ALL"
})

export const Budget_status = Object.freeze({
  ALL: null,
  INITIAL: "INITIAL",
  EXTENDED: "EXTENDED",
  INCREASED: "INCREASED",
  EXTENDED_INCREASED: "EXTENDED_INCREASED",
})


export const Logic_type = Object.freeze({
  GENERAL_OBJECTIVE: "generalObjective",
  SPECIFIC_OBJECTIVE: "specificObjective",
  RESULT: "result",
  ACTIVITY: "activity"
})


export const Budget_type = Object.freeze({
  SUMMARY: "SUMMARY",
  DETAIL: "DETAIL"
})
export const ProjectStatusList: any[] = [
  {
    name: Project_status.ALL
  },
  {
    name: Project_status.IN_PROGRESS
  },
  {
    name: Project_status.CANCELED
  },
  {
    name: Project_status.SUSPENDED,
  },
  {
    name: Project_status.COMPLETED,

  },
  {
    name: Project_status.NOT_STARTED,
  },
  {
    name: Project_status.MOVED
  }]

export const BudgetStatusList: any[] = [
  {
    name: Budget_status.ALL
  },
  {
    name: Budget_status.INITIAL
  },
  {
    name: Budget_status.INCREASED
  },
  {
    name: Budget_status.EXTENDED
  },
  {
    name: Budget_status.EXTENDED_INCREASED

  }]


