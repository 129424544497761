import {
  NgbDateParserFormatter,
  NgbDate,
  NgbDateStruct,
} from '@ng-bootstrap/ng-bootstrap';
import {Injectable} from '@angular/core';


function isNumber(value: any): boolean {
  return !isNaN(toInteger(value));
}

function toInteger(value: any): number {
  return parseInt(`${value}`, 10);
}

@Injectable()
export class NgbDateCustomParserFormatter extends NgbDateParserFormatter {
  parse(value: string): NgbDate {
    if (value) {
      const dateParts = value.trim().split('-');
      if (dateParts.length === 1 && isNumber(dateParts[0])) {
        return new NgbDate(toInteger(dateParts[0]), null, null);
      } else if (dateParts.length === 2 && isNumber(dateParts[0]) && isNumber(dateParts[1])) {
        return new NgbDate(toInteger(dateParts[0]), toInteger(dateParts[1]) - 1, null);
      } else if (dateParts.length === 3 && isNumber(dateParts[0]) && isNumber(dateParts[1]) && isNumber(dateParts[2])) {
        return new NgbDate(toInteger(dateParts[0]), toInteger(dateParts[1]) - 1, toInteger(dateParts[2]));
      }
    }
    return null;
  }

  static formatDate(date: NgbDateStruct | NgbDate | null): string {
    return `${date.year}-${date.month}-${date.day}`

  }

  format(date: NgbDateStruct | null): string {
    return date?date.year+"-"+('0'+date.month).slice(-2)
      +"-"+('0'+date.day).slice(-2):null
  }

}
