export class User {
  userId: string;
  firstName: string;
  lastName: string;
  email: string;
  firstTime: boolean;
  gender: string;
  middleName: string;
  userType: string;
  username: string;
  roles: string[];
  phoneNumber: string;
  active: boolean;
  fullName: string;
}

export class UserPost {

  firstName: string;
  lastName: string;
  email: string;
  gender: string;
  middleName: string;
  userType: string;
  addressId: number;
  phoneNumber: string;

}

