import {Component, ElementRef, Input, OnChanges, OnInit, SimpleChanges, ViewChild} from '@angular/core';
import {Plan} from "../project-detail/plan";
import {BehaviorSubject, Observable, of, Subject} from "rxjs";
import {debounceTime, delay, switchMap} from "rxjs/operators";
import {FormBuilder, FormControl, FormGroup, Validators} from "@angular/forms";
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";

interface planSearchResult {
  plans: Plan[];
  total: number;
}

interface State {
  page: number;
  pageSize: number;
  searchTerm: string;
  sortColumn: string;
  statusSearchTerm: boolean;
  startIndex: number;
  endIndex: number;
  totalRecords: number;
}

@Component({
  selector: 'app-project-plan',
  templateUrl: './project-plan.component.html',
  styleUrls: ['./project-plan.component.scss']
})
export class ProjectPlanComponent implements OnInit, OnChanges {
  public plans$ = new BehaviorSubject<Plan[]>([]);
  submit: boolean;
  @Input() plans: Plan[];
  @ViewChild("planModal") planModal: ElementRef;

  private _State: State;
  private _search$ = new Subject<void>();
  planForm: FormGroup;
  projectStatus: any = [{
    name: 'Inactive',
    value: 0
  }, {
    name: 'Active',
    value: 1
  }, {
    name: 'Cancelled',
    value: 2
  }];
  saving: boolean;
  formChanged: any | boolean;
  private modalRef: any;

  constructor(private _fb: FormBuilder, private _modalService: NgbModal,) {
  }

  ngOnInit(): void {
    this._State = {
      page: 1,
      pageSize: 5,
      searchTerm: "",
      statusSearchTerm: true,
      sortColumn: "",
      startIndex: 0,
      endIndex: 5,
      totalRecords: 0,
    };
    this.planForm = this._fb.group({

      name: new FormControl(null,
        {
          validators: [Validators.required],
          updateOn: 'change'

        }),
      type: new FormControl(null,
        {
          validators: [Validators.required],
          updateOn: 'change'

        }),
      measurement: new FormControl(null, [Validators.required],
      ),

      status: new FormControl(1, {
        validators: [Validators.required],
        updateOn: 'change'
      })
    });

    this._search$
      .pipe(
        debounceTime(100),
        switchMap(() => this._search()),
        delay(100),
      )
      .subscribe((result) => {
        this.plans$.next(result.plans);
      });

    this._search$.next();


  }

  set pageSize(pageSize: number) {
    this._set({pageSize});
  }


  set searchTerm(searchTerm: string) {
    this._set({searchTerm});
    this._search$.next();
  }

  set statusSearchTerm(statusSearchTerm: boolean) {
    this._set({statusSearchTerm});
    this._search$.next();
  }

  private _set(patch: Partial<State>) {
    Object.assign(this._State, patch);
    this._search$.next();
  }

  get startIndex() {
    return this._State.startIndex;
  }

  get total$() {
    return this._State.totalRecords;
  }

  get endIndex() {
    return this._State.endIndex;
  }

  get totalRecords() {
    return this._State.totalRecords;
  }

  get page() {
    return this._State.page;
  }

  get pageSize() {
    return this._State.pageSize;
  }

  get searchTerm() {
    return this._State.searchTerm;
  }


  get statusSearchTerm() {
    return this._State.statusSearchTerm;
  }

  set page(page: number) {
    this._set({page});
  }

  _search(): Observable<planSearchResult> {

    const {page, searchTerm, statusSearchTerm} =
      this._State;
    // 1. sort
    let plans = this.plans;
    const total = plans.length;
    // 3. paginate
    this._State.totalRecords = plans.length;
    this._State.startIndex = (page - 1) * this.pageSize + 1;
    this._State.endIndex = (page - 1) * this.pageSize + this.pageSize;

    if (this.endIndex > this.totalRecords) {
      this._State.endIndex = this.totalRecords;
    }
    plans = plans.slice(this._State.startIndex - 1, this._State.endIndex);

    return of({plans, total});
  }

  onEdit(uuid: string) {

  }

  onDelete(uuid: string, delete1: string) {

  }

  ngOnChanges(changes: SimpleChanges): void {
    this._search$.next();
  }

  onAddPlan() {

    this.modalRef = this._modalService.open(this.planModal, {
      scrollable: false,
      size: "md",
      centered: false,
    });

  }

  onNewPlan() {

  }

  get form() {
    return this.planForm.controls;
  }

  validSubmit() {

  }
}
