import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { Settings } from './settings';

@Injectable({
  providedIn: 'root'
})
export class SettingsService {

  constructor(private http_client: HttpClient) { }


  getSettings() {
    return this.http_client.get(environment.baseURL + '/application/setting');
  }


  updateSettings(setting: Settings) {
    return this.http_client.put(environment.baseURL + '/application/setting/update', setting);
  }
}
