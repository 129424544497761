<div class="mt-3 ">
  <div class="mb-3">

  </div>
  <div class="d-flex  justify-content-between">
    <h5 class="text-primary">Budget Summary</h5>
    <div class="">
      <button (click)="onAddBudgets()" class="btn btn-sm btn-outline-success">
        <i class="mdi mdi-plus"></i>
        Add Budget
      </button>
    </div>

  </div>
  <div class="col-sm-3 mb-3">
    <label><i class="bx bx-filter-alt text-primary"></i>Status</label>
    <ng-select [(ngModel)]="statusSearchTerm" [clearable]="true"
               [items]="budgetStatusList" bindLabel="name"
               bindValue="name" class="edit-input" placeholder="Filter by status">
    </ng-select>
  </div>
  <div class="table-responsive ">
    <table class="table table-sm table-hover table-striped">
      <thead>
      <tr>
        <th>Code</th>
        <th style="width: 20%;">Description</th>
        <th style="width: 10%;">Amount(USD)</th>
        <th style="width: 10%;">Amount(ETB)</th>
        <th>Start Date</th>
        <th>End Date</th>
        <th>Source</th>
        <th>Status</th>
        <th class="text-end" style="width: 20%;">Actions</th>
      </tr>
      </thead>
      <!--<tbody *ngFor="let budget of budgets$ | async ;let i=index"> -->
      <tbody>

      <ng-container *ngFor="let budget of budgets$|async">
        <tr>

          <td class="align-middle text-truncate ">
            {{
            budget.code
            }}
          </td>
          <td class="align-middle text-truncate ">
            {{
            budget.accountDescription
            }}
          </td>

          <td class="align-middle text-end">
            {{
            budget.budgetInUSD |currency
            }}
          </td>
          <td class="align-middle text-truncate  text-end ">
            {{
            budget.budgetInBirr |currency:'ETB'
            }}
          </td>
          <td class="align-middle text-truncate ">
            {{budget.startDate}}
          </td>

          <td class="align-middle text-truncate ">
            {{budget.endDate}}
          </td>
          <td class="align-middle text-truncate ">
            {{budget.source}}
          </td>

          <td
            [ngClass]="{'bg-light':budget.budgetStatus===Budget_status.INITIAL,
             'bg-self':budget.budgetStatus===Budget_status.EXTENDED,
              'bg-eval':budget.budgetStatus===Budget_status.EXTENDED_INCREASED,
              'bg-manager':budget.budgetStatus===Budget_status.INCREASED
                          }"
          >{{budget.budgetStatus}}</td>
          <td (click)="$event.stopPropagation()" class="align-middle">
            <div class="btn-group float-end">


              <button (click)="onEdit(budget)" class="btn btn-sm btn-outline-primary ">
                <i class="mdi mdi-pen "></i>
                Edit
              </button>

              <button (click)="showDetails(budget)" class="btn btn-sm btn-outline-info ">
                <i class="mdi mdi-view-list "></i>
                Details
              </button>

              <button (click)="onDelete(budget.uuid)"
                      class="btn btn-sm btn-outline-danger ">
                <i class="mdi mdi-trash-can-outline "></i>
                Delete
              </button>
            </div>
          </td>
        </tr>

        <!-- detail budgets table row (hidden by default) -->

        <div *ngIf="budget.showDetails" class="mb-2 mt-2 ">
          <button (click)="onAddDetailBudget(budget.uuid)" class="btn btn-sm btn-outline-success">
            <i class="mdi mdi-plus"></i>
            Add
          </button>
        </div>
        <tr class="table-info shadow-lg" *ngIf="budget.showDetails">

          <td colspan="9">
            <table class="table table-sm table-hover table-striped">
              <thead>
              <tr>
                <th>Code</th>
                <th style="width: 20%;">Description</th>
                <th style="width: 10%;">Amount(USD)</th>
                <th style="width: 10%;">Amount(ETB)</th>
                <th>Start Date</th>
                <th>End Date</th>
                <th>Source</th>
                <th>Status</th>
                <th class="text-end" style="width: 20%;">Actions</th>
              </tr>
              </thead>
              <!--<tbody *ngFor="let budget of budgets$ | async ;let i=index"> -->
              <tbody>
              <tr *ngFor="let detail of budget.details">

                <td class="align-middle text-truncate ">
                  {{
                  detail.code
                  }}
                </td>
                <td class="align-middle text-truncate ">
                  {{
                  detail.accountDescription
                  }}
                </td>

                <td class="align-middle text-truncate  text-end">
                  {{
                  detail.budgetInUSD | currency
                  }}
                </td>
                <td class="align-middle text-truncate  text-end">
                  {{
                  detail.budgetInBirr |currency:'ETB'
                  }}
                </td>
                <td class="align-middle text-truncate ">
                  {{detail.startDate}}
                </td>

                <td class="align-middle text-truncate ">
                  {{detail.endDate}}
                </td>
                <td class="align-middle text-truncate ">
                  {{detail.source}}
                </td>

                <td
                  [ngClass]="{'bg-light':detail.budgetStatus===Budget_status.INITIAL,
             'bg-self':detail.budgetStatus===Budget_status.EXTENDED,
              'bg-eval':detail.budgetStatus===Budget_status.EXTENDED_INCREASED,
              'bg-manager':detail.budgetStatus===Budget_status.INCREASED
                          }"
                >{{detail.budgetStatus}}</td>


                <td (click)="$event.stopPropagation()" class="align-middle">
                  <div class="btn-group float-end">


                    <button (click)="onEdit(detail)" class="btn btn-sm btn-outline-primary ">
                      <i class="mdi mdi-pen "></i>
                      Edit
                    </button>


                    <button (click)="onDelete(detail.uuid)"
                            class="btn btn-sm btn-outline-danger ">
                      <i class="mdi mdi-trash-can-outline "></i>
                      Delete
                    </button>
                  </div>
                </td>

              </tr>


              </tbody>

            </table>


          </td>
        </tr>

      </ng-container>
      </tbody>
    </table>
  </div>
  <!-- End table -->
  <div class="row justify-content-md-between align-items-md-center mt-2">
    <div class="col-sm-12 col-md-5">
      <div aria-live="polite" class="dataTables_info mb-2" id="tickets-table_info" role="status">Showing
        {{startIndex}} to
        {{endIndex}} of {{totalRecords}}
        entries
      </div>
    </div>
    <!-- Pagination -->
    <div class="col-sm-12 col-md-5">
      <div class="text-md-right float-md-end pagination-rounded">
        <ngb-pagination [(page)]="page" [collectionSize]="totalRecords" [pageSize]="pageSize">
        </ngb-pagination>
      </div>
    </div>
    <!-- End Pagination -->
  </div>
</div>

<ng-template #budgetFormModal let-modal>
  <div class="modal-header">
    <h5 class="modal-title mt-0">{{modalTitle}}</h5>
    <button (click)="modal.dismiss('Close click')" aria-label="Close" class="btn-close" type="button">
    </button>
  </div>

  <div class="modal-body ">

    <form (ngSubmit)="validSubmit()" [formGroup]="budgetForm">

      <div class="row">
        <div class="mb-3 col-sm-6 ">
          <label>Code <span class="text-danger"> *</span> </label>
          <input type="text" class="form-control edit-input" formControlName="code"
                 placeholder="Enter code"
                 [ngClass]="{'is-invalid' :(form.code.dirty || submit )  && form.code.errors}">
          <div *ngIf="(form.code.dirty || submit ) && form.code.errors" class="invalid-feedback">
            <span *ngIf="form.code.errors.required">Please provide a valid code.</span>
          </div>

        </div>
        <div class="mb-3 col-sm-6">
          <label>Description <span class="text-danger"> *</span> </label>
          <textarea type="text" class="form-control edit-input" formControlName="accountDescription"
                    placeholder="Enter Description"
                    [ngClass]="{'is-invalid' :(form.accountDescription.dirty || submit )  && form.accountDescription.errors}"> </textarea>
          <div *ngIf="(form.accountDescription.dirty || submit ) && form.accountDescription.errors"
               class="invalid-feedback">
            <span *ngIf="form.accountDescription.errors.required">Please provide a valid description.</span>
          </div>

        </div>
      </div>
      <div class="row ">

        <div class="mb-3 col-sm-6">
          <label> Start Date<span class="text-danger"> *</span> </label>
          <input type="text" class="form-control date-cursor " formControlName="startDate"
                 (click)="ldd.toggle()" ngbDatepicker #ldd="ngbDatepicker"
                 autocomplete="off"
                 placeholder="Enter Start Date"
                 [ngClass]="{'is-invalid' :(form.startDate.dirty || submit) && form.startDate.errors}">
          <div *ngIf="(form.startDate.dirty || submit) && form.startDate.errors" class="invalid-feedback">
            <span *ngIf="form.startDate.errors">Please provide a valid date.</span>
          </div>
        </div>

        <div class="mb-3 col-sm-6">
          <label> End Date<span class="text-danger"> *</span> </label>
          <input type="text" class="form-control date-cursor"
                 (click)="edd.toggle()" ngbDatepicker #edd="ngbDatepicker"
                 autocomplete="off" formControlName="endDate" placeholder="Enter End Date"
                 [ngClass]="{'is-invalid' :(form.endDate.dirty || submit) && form.endDate.errors }">
          <div *ngIf="(form.endDate.dirty || submit) && form.endDate.errors" class="invalid-feedback">
            <span *ngIf="form.endDate.errors">Please provide a valid date.</span>
          </div>
        </div>

      </div>
      <div class="row">
        <div class="mb-3 col-sm-6 ">
          <label>Budget (USD) <span class="text-danger"> *</span></label>
          <input currencyMask type="text" class="form-control edit-input" formControlName="budgetInUSD"
                 placeholder="Enter budget amount"
                 [ngClass]="{'is-invalid' :(form.budgetInUSD.dirty || submit )  && form.budgetInUSD.errors}">
          <div *ngIf="(form.budgetInUSD.dirty || submit ) && form.budgetInUSD.errors" class="invalid-feedback">
            <span *ngIf="form.budgetInUSD.errors.required">Please provide a valid budget.</span>
          </div>
        </div>

        <div class="mb-3 col-sm-6 ">
          <label>Source</label>
          <input type="text" class="form-control edit-input" formControlName="source"
                 placeholder="Enter source"
                 [ngClass]="{'is-invalid' :(form.source.dirty || submit )  && form.source.errors}">
          <div *ngIf="(form.source.dirty || submit ) && form.source.errors" class="invalid-feedback">
            <span *ngIf="form.source.errors.required">Please provide a valid source.</span>
          </div>
        </div>
      </div>
      <div *ngIf="isUpdate" class="row">

        <div class="mb-3 col-sm-6 ">
          <label>Budget Status <span class="text-danger"> *</span> </label>
          <ng-select [clearable]="false" [items]="budgetStatusList"
                     [ngClass]="{'is-invalid' :submit && form.budgetStatus.errors}"
                     bindLabel="name" bindValue="name" formControlName="budgetStatus">
          </ng-select>

          <div *ngIf="(form.budgetStatus.dirty || submit) && form.budgetStatus.errors" class="invalid-feedback">
            <span *ngIf="form.budgetStatus.errors">Please choose a valid status.</span>
          </div>
        </div>


      </div>


      <div class="d-flex flex-wrap gap-2">
        <button [disabled]="saving || !formChanged " class="btn btn-primary" type="submit">Save
          <span *ngIf="saving" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
        </button>
        <button type="reset" [disabled]="saving " class="btn btn-secondary waves-effect">
          Reset
        </button>
      </div>

    </form>

  </div>

</ng-template>



