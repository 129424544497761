import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ProjectRoutingModule } from './project-routing.module';
import { ProjectListComponent } from './project-list/project-list.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { UIModule } from "../../shared/ui/ui.module";
import {NgbAlertModule, NgbTooltipModule} from '@ng-bootstrap/ng-bootstrap';
import { NgSelectModule } from '@ng-select/ng-select';
import { NgxMaskModule } from 'ngx-mask'
import {
  NgbDropdownModule,
  NgbNavModule,
  NgbModalModule,
  NgbPaginationModule,
  NgbTypeaheadModule,
  NgbDatepickerModule,
} from "@ng-bootstrap/ng-bootstrap";
import { ProjectFormComponent } from './project-form/project-form.component';
import {CurrencyMaskModule} from "ng2-currency-mask";
import { ProjectDetailComponent } from './project-detail/project-detail.component';
import { ProjectPlanComponent } from './project-plan/project-plan.component';
import { ProjectOutcomeComponent } from './project-outcome/project-outcome.component';
import { ProjectBudgetsComponent } from './project-budgets/project-budgets.component';
import { IndicatorsComponent } from './indicators/indicators.component';
import { InterventionLogicFormComponent } from './intervention-logic-form/intervention-logic-form.component';
import { InterventionLogicComponentComponent } from './intervention-logic-component/intervention-logic-component.component';
import { BudgetUtilizationComponent } from './budget-utilization/budget-utilization.component';
import { ExtensionsComponent } from './extensions/extensions.component';


@NgModule({
  declarations: [
    ProjectListComponent,
    ProjectFormComponent,
    ProjectDetailComponent,
    ProjectPlanComponent,
    ProjectOutcomeComponent,
    ProjectBudgetsComponent,
    IndicatorsComponent,
    InterventionLogicFormComponent,
    InterventionLogicComponentComponent,
    BudgetUtilizationComponent,
    ExtensionsComponent
  ],
    imports: [
        CommonModule,
        NgbDropdownModule,
        NgbAlertModule,
        NgbNavModule,
        NgbModalModule,
        ReactiveFormsModule,
        NgbPaginationModule,
        NgbTypeaheadModule,
        NgbDatepickerModule,
        ProjectRoutingModule,
        FormsModule,
        UIModule,
        NgxMaskModule.forRoot(),
        NgSelectModule,
        CurrencyMaskModule,
        NgbTooltipModule
    ]
})
export class ProjectModule { }
