import {Injectable} from "@angular/core";
import {Resolve} from "@angular/router";
import {Observable, of} from "rxjs";
import {catchError} from "rxjs/operators";
import {ProjectService} from "../services/project/project.service";

@Injectable({
  providedIn: "root",
})
export class ProjectResolver implements Resolve<boolean> {
  constructor(private _projectService: ProjectService) {
  }

  resolve(): Observable<any> {

    return this._projectService.getAll().pipe(
      catchError((error) => {
        return of("No data");
      })
    );

  }
}
