import {Component, ElementRef, EventEmitter, Input, OnInit, Output, SimpleChanges, ViewChild} from '@angular/core';
import {Budget} from "../project-detail/budget";
import {BehaviorSubject, Observable, of, Subject} from "rxjs";
import {debounceTime, delay, switchMap} from "rxjs/operators";
import {Budget_status, Budget_type, BudgetStatusList, Logic_type} from "../../../shared/models/Roles";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {LoaderService} from "../../../core/services/shared/loader.service";
import {ProjectService} from "../../../core/services/project/project.service";
import {NgbDateCustomParserFormatter} from "../../../core/helpers/ngb-date-formatter";
import {DateHelper} from "../../../core/helpers/Util";
import {Project} from "../../../core/services/project/project";


interface budgetSearchResult {
  budgets: Budget[];
  total: number;
}

interface State {
  page: number;
  pageSize: number;
  searchTerm: string;
  sortColumn: string;
  statusSearchTerm: string;
  startIndex: number;
  endIndex: number;
  totalRecords: number;
}

@Component({
  selector: 'app-project-budgets',
  templateUrl: './project-budgets.component.html',
  styleUrls: ['./project-budgets.component.scss']
})
export class ProjectBudgetsComponent implements OnInit {


  public budgets$ = new BehaviorSubject<Budget[]>([]);

  @Input() budgets: Budget[];
  @Input() project: Project;
  @Input() type: string;
  @Output() refreshEvent: EventEmitter<any> = new EventEmitter<any>();
  @Output() errorEvent: EventEmitter<any> = new EventEmitter<any>();
  private _State: State;
  submit: boolean;
  budgetForm: FormGroup;
  modalRef: any;
  saving: boolean;
  isUpdate: boolean;
  formChanged: any | boolean;
  budgetForUpdate: Budget;
  formInitialValue: any;
  private _search$ = new Subject<void>();
  @ViewChild("budgetFormModal") budgetModal: ElementRef;

  constructor(private fb: FormBuilder,
              private modalService: NgbModal,
              private dateFormatter: NgbDateCustomParserFormatter,
              private _loaderService: LoaderService,
              private projectService: ProjectService) {
  }

  ngOnInit(): void {
    this._State = {
      page: 1,
      pageSize: 5,
      searchTerm: "",
      statusSearchTerm: null,
      sortColumn: "",
      startIndex: 0,
      endIndex: 5,
      totalRecords: 0,
    };
    this._search$
      .pipe(
        debounceTime(100),
        switchMap(() => this._search()),
        delay(100),
      )
      .subscribe((result) => {
        this.budgets$.next(result.budgets);
      });

    this._search$.next();
    this.budgetForm = this.fb.group({
      accountDescription: [null, Validators.required],
      activity: [null],
      budgetInUSD: [null, Validators.required],
      startDate: [null, Validators.required],
      endDate: [null, Validators.required],
      budgetStatus: [null],
      budgetType: [null],
      source: [null, Validators.required],
      code: [null, Validators.required],
      parentBudgetUuid: [null],
    })

    //subscribe to form change
    this.budgetForm.valueChanges.subscribe((value) => {
      this.formChanged = this.formInitialValue ? Object.keys(this.formInitialValue)
        .some(key => this.budgetForm.value[key] !==
          this.formInitialValue[key]) : true;
    });

  }


  get form() {
    return this.budgetForm.controls;
  }

  set pageSize(pageSize: number) {
    this._set({pageSize});
  }


  set searchTerm(searchTerm: string) {
    this._set({searchTerm});
    this._search$.next();
  }

  set statusSearchTerm(statusSearchTerm: string) {
    this._set({statusSearchTerm});
    this._search$.next();
  }

  private _set(patch: Partial<State>) {
    Object.assign(this._State, patch);
    this._search$.next();
  }

  get startIndex() {
    return this._State.startIndex;
  }

  get total$() {
    return this._State.totalRecords;
  }

  get endIndex() {
    return this._State.endIndex;
  }

  get totalRecords() {
    return this._State.totalRecords;
  }

  get page() {
    return this._State.page;
  }

  get pageSize() {
    return this._State.pageSize;
  }

  get searchTerm() {
    return this._State.searchTerm;
  }


  get statusSearchTerm() {
    return this._State.statusSearchTerm;
  }

  set page(page: number) {
    this._set({page});
  }

  _search(): Observable<budgetSearchResult> {

    const {page, searchTerm, statusSearchTerm} =
      this._State;
    // 1. sort
    let budgets = this.budgets;
    const total = budgets.length;
    // 3. paginate
    this._State.totalRecords = budgets.length;
    this._State.startIndex = (page - 1) * this.pageSize + 1;
    this._State.endIndex = (page - 1) * this.pageSize + this.pageSize;
    if (this.endIndex > this.totalRecords) {
      this._State.endIndex = this.totalRecords;
    }
    budgets = budgets.slice(this._State.startIndex - 1, this._State.endIndex);

    return of({budgets, total});
  }

  onEdit(budget: Budget) {
    this.budgetForUpdate = budget;
    this.modalTitle = "Update Budget"
    this.isUpdate = true;
    this.budgetForm.patchValue(budget);
    this.form.startDate.setValue(DateHelper.getDateFromString(budget.startDate))
    this.form.endDate.setValue(DateHelper.getDateFromString(budget.endDate))
    this.modalRef = this.modalService
      .open(this.budgetModal, {
        scrollable: false,
        size: "md",
        centered: false,
      });
  }

  onDelete(uuid: string) {
    this._loaderService.showProgress.next(true);
    this.projectService.deleteBudget(this.project.uuid, uuid).subscribe(
      () => {
        this._loaderService.showProgress.next(false);
        this.refreshEvent.emit();

      },
      (error) => {
        console.log(error);
        this._loaderService.showProgress.next(false);
        this.errorEvent.emit();

      }
    );

  }

  ngOnChanges(changes: SimpleChanges): void {
    this._search$.next();
  }

  onAddBudgets() {
    this.budgetForm.reset()
    this.modalTitle = "Add Budget"
    this.isUpdate = false;
    this.modalRef = this.modalService
      .open(this.budgetModal, {
        scrollable: false,
        size: "md",
        centered: false,
      });
  }

  readonly Budget_status = Budget_status;
  modalTitle: any;

  validSubmit() {
    this.submit = true;
    if (this.budgetForm.valid) {
      this.form.budgetType.setValue(this.type)
      this.isUpdate ? this.updateBudget() : this.submitBudget();
    } else {
      console.log(this.budgetForm.value)
    }

  }

  submitBudget() {
    const formData = this.budgetForm.value;
    formData.startDate = this.dateFormatter.format(this.form.startDate.value)
    formData.endDate = this.dateFormatter.format(this.form.endDate.value)
    this._loaderService.showProgress.next(true);

    this.projectService.addBudget(this.project.uuid, formData)
      .subscribe(
        () => {
          this._loaderService.showProgress.next(false);
          this.refreshEvent.emit();
        },
        (error) => {
          this.saving = false;
          this.errorEvent.emit(error);
          this._loaderService.showProgress.next(false);

        }
      );

  }

  updateBudget() {
    const formData = this.budgetForm.value;
    formData.startDate = this.dateFormatter.format(this.form.startDate.value)
    formData.endDate = this.dateFormatter.format(this.form.endDate.value)

    this._loaderService.showProgress.next(true);
    this.projectService.updateBudget(this.project.uuid, this.budgetForUpdate.uuid, formData)
      .subscribe(
        () => {
          this._loaderService.showProgress.next(false);
          this.refreshEvent.emit();
        },
        (error) => {
          this.saving = false;
          this.errorEvent.emit(error);
          this._loaderService.showProgress.next(false);

        }
      );

  }

  readonly budgetStatusList = BudgetStatusList;
  protected readonly Logic_type = Logic_type;

  showDetails(budget: Budget) {
    budget.showDetails = !budget.showDetails;
  }

  onAddDetailBudget(uuid: string) {
    this.budgetForm.reset();
    this.type = Budget_type.DETAIL
    this.form.parentBudgetUuid.setValue(uuid)
    this.modalTitle = "Add Budget"
    this.isUpdate = false;
    this.modalRef = this.modalService
      .open(this.budgetModal, {
        scrollable: false,
        size: "md",
        centered: false,
      });
  }
}
