import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Logic_type} from "../../../shared/models/Roles";
import {InterventionLogic, Project} from "../../../core/services/project/project";
import {InterventionLogicFormComponent} from "../intervention-logic-form/intervention-logic-form.component";
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {LoaderService} from "../../../core/services/shared/loader.service";
import {ProjectService} from "../../../core/services/project/project.service";
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'app-intervention-logic-component',
  templateUrl: './intervention-logic-component.component.html',
  styleUrls: ['./intervention-logic-component.component.scss']
})

export class InterventionLogicComponentComponent implements OnInit {

  @Input() logics: BehaviorSubject<InterventionLogic[]>= new  BehaviorSubject(null);

  @Input() project: Project
  @Input() logicType: string
  @Input() parentLabel: string
  @Input() hasActivities: boolean
  @Input() hasParent: boolean
  @Input() enableAdd: boolean = true;
  @Input() parentLogics: BehaviorSubject<InterventionLogic[]>= new  BehaviorSubject(null);
  @Input() title: string
  @Output() refreshDataEvent: EventEmitter<any> = new EventEmitter<any>();
  @Output() errorEvent: EventEmitter<any> = new EventEmitter<any>();
  modalRef: any;
  addBtnName: string;
  filteredLogics: BehaviorSubject<InterventionLogic[]>= new  BehaviorSubject(null);

  constructor(private modalService: NgbModal,
              private _loaderService: LoaderService,
              private _projectService: ProjectService) {
  }

  ngOnInit(): void {
    this.addBtnName = `Add ${this.title}`
    this.filteredLogics = this.logics;
  }

  readonly Logic_type = Logic_type;
  soFilter: any;

  onUpdateInterventionLogic(data: InterventionLogic, logicType: string, hasParent: boolean, parentList ?: any, parentLabel ?: string, customTitle?: string) {
    this.modalRef = this.modalService
      .open(InterventionLogicFormComponent, {
        scrollable: false,
        size: "sm",
        centered: true,
      });
    this.modalRef.componentInstance.modalTitle = customTitle ? `Update ${customTitle}` : `Update ${this.title}`;
    this.modalRef.componentInstance.logicType = logicType;
    this.modalRef.componentInstance.data = data;
    this.modalRef.componentInstance.isUpdate = true;
    this.modalRef.componentInstance.hasParent = hasParent;
    this.modalRef.componentInstance.parentList = parentList;
    this.modalRef.componentInstance.parentLabel = parentLabel;
    this.modalRef.componentInstance.dataChanged.subscribe(() => this.refreshDataEvent.emit());
    this.modalRef.componentInstance.error_event.subscribe((err) => this.errorEvent.emit(err));
  }

  onAddInterventionLogic(logicType: string, hasParent: boolean, parentList ?: any, parentLabel ?: string) {
    this.modalRef = this.modalService
      .open(InterventionLogicFormComponent, {
        scrollable: false,
        size: "sm",
        centered: true,
      });
    this.modalRef.componentInstance.modalTitle = `Add ${this.title}`;
    this.modalRef.componentInstance.logicType = logicType;
    this.modalRef.componentInstance.hasParent = hasParent;
    this.modalRef.componentInstance.projectUUId = this.project.uuid;
    this.modalRef.componentInstance.parentLabel = parentLabel;
    this.modalRef.componentInstance.parentList = parentList;
    this.modalRef.componentInstance.dataChanged.subscribe(() => this.refreshDataEvent.emit());
    this.modalRef.componentInstance.error_event.subscribe((err) => this.errorEvent.emit(err));
  }

  onDeleteInterventionLogic(uuid: string, logicType: string) {
    this._loaderService.showProgress.next(true);
    this._projectService.deleteLogic(this.project.uuid, logicType, uuid).subscribe(
      () => {
        this._loaderService.showProgress.next(false);
        this.refreshDataEvent.emit();

      },
      (error) => {
        console.log(error);
        this._loaderService.showProgress.next(false);
        this.errorEvent.emit();

      }
    );


  }

  onResultFilter($event) {
    if ($event === undefined) {
      this.filteredLogics = this.logics
    } else {
      this.filteredLogics.next( this.logics.value.filter(logic => logic.parentUuid === $event.uuid));
    }
  }

  onAddActivity(logicType: string, hasParent: boolean, parentList ?: any, parentLabel ?: string) {
    this.modalRef = this.modalService
      .open(InterventionLogicFormComponent, {
        scrollable: false,
        size: "sm",
        centered: true,
      });
    this.modalRef.componentInstance.modalTitle = `Add Activity`;
    this.modalRef.componentInstance.logicType = logicType;
    this.modalRef.componentInstance.hasParent = hasParent;
    this.modalRef.componentInstance.projectUUId = this.project.uuid;
    this.modalRef.componentInstance.parentLabel = parentLabel;
    this.modalRef.componentInstance.parentList = parentList;
    this.modalRef.componentInstance.dataChanged.subscribe(() => this.refreshDataEvent.emit());
    this.modalRef.componentInstance.error_event.subscribe((err) => this.errorEvent.emit(err));
  }

  showSubLogics(logic: InterventionLogic) {
    logic.showSublogics = !logic.showSublogics;
  }

  showIndicators(logic: InterventionLogic) {
    logic.indicatorsVisible = !logic.indicatorsVisible;
  }
}
