import {NgModule} from "@angular/core";
import {RouterModule, Routes} from "@angular/router";
import {LayoutComponent} from "./layouts/layout.component";
import {Page401Component} from "./modules/page401/page401.component";
import {Page404Component} from "./modules/page404/page404.component";
import {SuperAdminGuard} from "./core/guards/super-admin.guard";
import {ProjectGuard} from "./core/guards/project-guard.service";

const routes: Routes = [


  {
    path: "account",
    loadChildren: () =>
      import("./modules/account/account.module").then((m) => m.AccountModule),
  },
  {
    path: "users",
    canActivate: [SuperAdminGuard],
    component: LayoutComponent,
    loadChildren: () =>
      import("./modules/users/users.module").then((m) => m.UsersModule),
  },
  {
    path: "projects",
    canActivate: [ProjectGuard],
    component: LayoutComponent,
    loadChildren: () =>
      import("./modules/project/project.module").then((m) => m.ProjectModule),
  },

  //Dashboard module
  {
    path: "dashboard",
    component: LayoutComponent,
    loadChildren: () =>
      import("./modules/dashboard/dashboard.module")
        .then((m) => m.DashboardModule),
  },

  {
    path: "404",
    component: Page404Component
  },

  {
    path: "unauthorized",
    component: Page401Component
  },


  {
    path: "",
    pathMatch: "full",
    redirectTo: '/dashboard/admin'
  },


  {path: '**', redirectTo: '/404'},


];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      scrollPositionRestoration: "top",
      relativeLinkResolution: "legacy",
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {
}
