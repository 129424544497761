import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {ProjectListComponent} from './project-list/project-list.component';
import {ProjectResolver} from "../../core/resolvers/project.resolver";
import {ProjectDetailComponent} from "./project-detail/project-detail.component";
import {ProjectProfileResolver} from "../../core/resolvers/Project-profile.resolver";

const routes: Routes = [

  {
    path: "",
    resolve: {
      projects: ProjectResolver
    },
    component: ProjectListComponent
  },


  {
    path: "details",
    resolve: {
      projectData: ProjectProfileResolver
    },
    component: ProjectDetailComponent
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ProjectRoutingModule {
}
