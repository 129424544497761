import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {User} from 'app/core/services/user/user';
import {BehaviorSubject, Observable, of, Subject} from "rxjs";
import {debounceTime, delay, switchMap, tap} from "rxjs/operators";
import {Router} from "@angular/router";
import {AccountActionEvent} from 'app/modules/users';

interface userSearchResult {
  users: User[];
  total: number;
}


interface State {
  page: number;
  pageSize: number;
  searchTerm: string;
  sortColumn: string;
  statusSearchTerm: boolean;
  startIndex: number;
  endIndex: number;
  totalRecords: number;
}

function matches(user: User, term: string) {
  return (
    user.firstName.toLowerCase().includes(term) ||
    user.lastName.toLowerCase().includes(term)
  );
}

function matchesAccountStatus(user: User, status: boolean) {

  return (status == null) ? true : (user.active === status);

}


const compare = (v1: string, v2: string) => (v1 < v2 ? -1 : v1 > v2 ? 1 : 0);

@Component({
  selector: 'user-table',
  templateUrl: './user-table.component.html',
  styleUrls: ['./user-table.component.scss']
})
export class UserTableComponent implements OnInit, OnChanges {

  private _State: State;
  accountStatus: any[] = [{name: "All", enabled: null}, {name: "Active", enabled: true}, {
    name: "Disabled",
    enabled: false
  }]
  private _loading$ = new BehaviorSubject<boolean>(true);
  private _search$ = new Subject<void>();
  private _totalUsers$ = new BehaviorSubject<number>(0);
  public users$: BehaviorSubject<User[]> = new BehaviorSubject<User[]>([]);
  @Input() userList: User[];
  @Input() disableAllowed: boolean;
  @Output() editUser: EventEmitter<string> = new EventEmitter<string>();
  @Output() accountAction: EventEmitter<AccountActionEvent> = new EventEmitter<AccountActionEvent>();

  constructor(private _router: Router) {
  }

  ngOnChanges(changes: SimpleChanges): void {
    this._search$.next();
  }


  ngOnInit(): void {

    this._State = {
      page: 1,
      pageSize: 5,
      searchTerm: "",
      statusSearchTerm: true,
      sortColumn: "",
      startIndex: 0,
      endIndex: 5,
      totalRecords: 0,
    };
    this._search$
      .pipe(
        tap(() => this._loading$.next(true)),
        debounceTime(100),
        switchMap(() => this._search()),
        delay(100),
        tap(() => this._loading$.next(false))
      )
      .subscribe((result) => {
        this.users$.next(result.users);
        this._totalUsers$.next(result.total);
      });

    this._search$.next();
  }


  set pageSize(pageSize: number) {
    this._set({pageSize});
  }


  set searchTerm(searchTerm: string) {
    this._set({searchTerm});
    this._search$.next();
  }

  set statusSearchTerm(statusSearchTerm: boolean) {
    this._set({statusSearchTerm});
    this._search$.next();
  }

  private _set(patch: Partial<State>) {
    Object.assign(this._State, patch);
    this._search$.next();
  }

  get startIndex() {
    return this._State.startIndex;
  }

  get total$() {
    return this._totalUsers$.asObservable();
  }

  get endIndex() {
    return this._State.endIndex;
  }

  get totalRecords() {
    return this._State.totalRecords;
  }

  get page() {
    return this._State.page;
  }

  get pageSize() {
    return this._State.pageSize;
  }

  get searchTerm() {
    return this._State.searchTerm;
  }


  get statusSearchTerm() {
    return this._State.statusSearchTerm;
  }

  set page(page: number) {
    this._set({page});
  }

  _search(): Observable<userSearchResult> {

    const {page, searchTerm, statusSearchTerm} =
      this._State;
    // 1. sort
    let users = this.userList;

    // 2. filter
    users = users.filter((user) => matches(user, searchTerm));

    //filter by account status
    users = users.filter((user) => matchesAccountStatus(user, statusSearchTerm))
    const total = users.length;
    // 3. paginate
    this._State.totalRecords = users.length;
    this._State.startIndex = (page - 1) * this.pageSize + 1;
    this._State.endIndex = (page - 1) * this.pageSize + this.pageSize;

    if (this.endIndex > this.totalRecords) {
      this._State.endIndex = this.totalRecords;
    }
    users = users.slice(this._State.startIndex - 1, this._State.endIndex);

    return of({users, total});
  }

  onEdit(userId: string) {
    this.editUser.emit(userId);
  }

  onAccountAction(userid: string, action: "enable" | "disable" | "delete" | "reset") {
    this.accountAction.emit({
      action: action,
      userId: userid
    });
  }


}
