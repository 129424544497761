import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {
  NgbCollapseModule,
  NgbDropdownModule,
  NgbModalModule,
  NgbNavModule,
  NgbTooltipModule
} from '@ng-bootstrap/ng-bootstrap';
import {NgxChartistModule} from 'ngx-chartist';
import {NgxEchartsModule} from 'ngx-echarts';
import {FullCalendarModule} from '@fullcalendar/angular';
import {SimplebarAngularModule} from 'simplebar-angular';
import dayGridPlugin from '@fullcalendar/daygrid'; // a plugin
import interactionPlugin from '@fullcalendar/interaction';
import bootstrapPlugin from "@fullcalendar/bootstrap";
import {LightboxModule} from 'ngx-lightbox';
import {AccountModule} from './account/account.module';
import {UsersModule} from './users/users.module'
import {ProjectModule} from "./project/project.module";



FullCalendarModule.registerPlugins([ // register FullCalendar plugins
  dayGridPlugin,
  interactionPlugin,
  bootstrapPlugin
]);

@NgModule({


  imports: [
    NgxEchartsModule,
    NgxChartistModule,
    CommonModule,
    FormsModule,
    NgbDropdownModule,
    NgbModalModule,
    AccountModule,
    ReactiveFormsModule,
    UsersModule,
    ProjectModule,
    FullCalendarModule,
    NgbNavModule,
    NgbTooltipModule,
    NgbCollapseModule,
    SimplebarAngularModule,
    LightboxModule
  ],
})
export class PagesModule {
}
