import {ActivatedRouteSnapshot, Resolve, RouterStateSnapshot} from "@angular/router";
import {Project} from "../services/project/project";
import {Injectable} from "@angular/core";
import {ProjectService} from "../services/project/project.service";
import {forkJoin, Observable} from "rxjs";
import {Budget_type, Logic_type} from "../../shared/models/Roles";

@Injectable({
  providedIn: 'root'
})
export class ProjectProfileResolver implements Resolve<Project> {

  constructor(private projectService: ProjectService) {
  }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> {
    const uuid = route.queryParams.uuid;
    const project = this.projectService.getById(uuid);
    const generalObjective = this.projectService.getLogic(uuid, Logic_type.GENERAL_OBJECTIVE);
    const specificObjective = this.projectService.getLogic(uuid, Logic_type.SPECIFIC_OBJECTIVE);
    const result = this.projectService.getLogic(uuid, Logic_type.RESULT);
    const activity = this.projectService.getLogic(uuid, Logic_type.ACTIVITY);
    const budgets = this.projectService.getBudgets(uuid, Budget_type.SUMMARY);

    return forkJoin({
      project,
      generalObjective,
      specificObjective,
      result,
      activity,
      budgets
    });
  }
}
