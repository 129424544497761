import {Component, ElementRef, Inject, OnInit, ViewChild} from '@angular/core';
import {InterventionLogic, Project} from "../../../core/services/project/project";
import {Breadcrumb} from "../../../core/services/shared/breadcrumb.service";
import {ActivatedRoute} from "@angular/router";
import {ProjectService} from "../../../core/services/project/project.service";
import {LoaderService} from "../../../core/services/shared/loader.service";
import {Logic_type, Project_status} from "../../../shared/models/Roles";
import {NgbAlert, NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {ProjectProfileResolver} from "../../../core/resolvers/Project-profile.resolver";
import {BehaviorSubject, Subject} from "rxjs";
import {debounceTime} from "rxjs/operators";
import {Budget} from "./budget";
import {DomSanitizer} from "@angular/platform-browser";
import {environment} from "../../../../environments/environment";

@Component({
  selector: 'app-project-detail',
  templateUrl: './project-detail.component.html',
  styleUrls: ['./project-detail.component.scss']
})
export class ProjectDetailComponent implements OnInit {
  project: Project;
  uuid: string;
  breadCrumbItems: Breadcrumb[];
  budgets: Budget[];
  specificObjectives: BehaviorSubject<InterventionLogic[]> = new BehaviorSubject(null);
  results: BehaviorSubject<InterventionLogic[]> = new BehaviorSubject(null);
  activities: BehaviorSubject<InterventionLogic[]> = new BehaviorSubject(null);
  generalObjectives: BehaviorSubject<InterventionLogic[]> = new BehaviorSubject(null);
  _message = new Subject<string>();
  message = "";
  alertType = "";
  iframeUrl;

  @ViewChild("selfClosingAlert", {static: false}) selfClosingAlert: NgbAlert;
  readonly Project_status = Project_status;
  readonly Logic_type = Logic_type;
  @ViewChild('fileInput') fileInput: ElementRef;
  selectedFile: File;
  uploading: boolean = false;

  constructor(private activateRouter: ActivatedRoute,
              private loaderService: LoaderService,
              private sanitizer: DomSanitizer,
              private modalService: NgbModal,
              private projectResolver: ProjectProfileResolver,
              private _loaderService: LoaderService,
              private _projectService: ProjectService
  ) {
  }

  ngOnInit(): void {

    this.breadCrumbItems = [

      {
        label: "Projects",
        url: "/projects",
        active: true
      },
      {
        label: "details",
        url: "",
        active: false
      }]
    this.activateRouter.data.subscribe(data => {
      this.project = data.projectData.project;
      this.generalObjectives.next(data.projectData.generalObjective);
      this.specificObjectives.next(data.projectData.specificObjective);
      this.results.next(data.projectData.result);
      this.activities.next(data.projectData.activity);
      this.budgets = data.projectData.budgets;
    });
    //config alert
    this._message.pipe(debounceTime(3000)).subscribe(() => {
      if (this.selfClosingAlert) {
        this.selfClosingAlert.close();
      }
    });
  }

  onFileSelected(event: any) {
    this.selectedFile = event.target.files[0];
  }

  uploadFile() {
    if (this.selectedFile) {
      this.uploading = true;
      this.loaderService.showProgress.next(true);
      const formData = new FormData();
      formData.append('file', this.selectedFile);
      this._projectService.uploadSurvey(formData, this.project.uuid).subscribe({
        next: (resp) => {
          this.uploading = false;
          this.showSuccess()
          this.resetInput();
          this.loaderService.showProgress.next(false);
        }, error: (err) => {

          this.uploading = false;
          this.showError(err.error.message)
          this.loaderService.showProgress.next(false);
        }
      })


    } else {

    }
  }

  resetInput() {
    this.fileInput.nativeElement.value = '';
    this.selectedFile = null;
  }

  getDashboardUrl() {
    return this.sanitizer
      .bypassSecurityTrustResourceUrl(`${environment.METABASE_SITE_URL}/${this.project.dashboardPublicId}`);
  }

  refreshData() {
    this.showSuccess();
    this.modalService.dismissAll();
    this.projectResolver.resolve(this.activateRouter.snapshot, null).subscribe(data => {
      this.project = data.project;
      this.generalObjectives.next(data.generalObjective);
      this.specificObjectives.next(data.specificObjective);
      this.results.next(data.result);
      this.activities.next(data.activity);
      this.budgets = data.budgets;
    });
  }

  showError(err) {
    console.log(err)
    this.modalService.dismissAll();
    //success notification
    this.alertType = "danger";
    this._message.next(this.message);
    this.message = "Oops, something went wrong!";
  }

  showSuccess() {
    //success notification
    this.alertType = "success";
    this._message.next(this.message);
    this.message = "Action performed Successfully!";
  }


}
