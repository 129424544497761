import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { SettingsService } from 'app/core/services/settings/settings.service';
import { LoaderService } from 'app/core/services/shared/loader.service';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.scss']
})
export class SettingsComponent implements OnInit {

  settingsForm: FormGroup
  submit: boolean;
  _message = new Subject<string>();
  message = "";
  alertType = "";
  constructor(private formBuilder: FormBuilder,
    private settingsSerivce: SettingsService, private _loaderService: LoaderService) { }

  ngOnInit(): void {
    this.settingsForm = this.formBuilder.group({
      rate: [null],
      annualEvaluationReminderChronExpression: [null],
      halfYearEvaluationReminderChronExpression: [null],
      probationPeriodReminder: [null]
    })

    this.settingsSerivce.getSettings().subscribe({
      next: (response) => {
        this.settingsForm.patchValue(response);
      },
      error: (err) => {
        console.log(err);

      }

    })
  }

  submitSettings() {
    this.submit = true;

    if (this.settingsForm.valid) {
      this._loaderService.showProgress.next(true);
      this.settingsSerivce.updateSettings(this.settingsForm.value).subscribe(
        (response) => {

          this._loaderService.showProgress.next(false);
          this.settingsForm.patchValue(response);

          //success notification
          this.alertType = "success";
          this._message.next(this.message);
          this.message = "Settings updated Successfully!";


        },
        (error) => {
          console.log(error);
          this._loaderService.showProgress.next(false);

          //error notification
          this.alertType = "danger";
          this._message.next(this.message);
          this.message = "Error updating Settings! please try again later";
        }
      );
    }

  }
  get form() {
    return this.settingsForm.controls;
  }
}
