import {Injectable} from "@angular/core";
import {Router} from "@angular/router";
import {RolesEnum} from "app/shared/models/Roles";
import {User} from "../user/user";
import {AuthService} from "./auth.service";

@Injectable({
  providedIn: "root",
})
export class RoleBasedRoutingService {
  constructor(private router: Router, private authService: AuthService) {
  }

  routeToLanding(user: User) {
    switch (true) {
      case user.roles.includes(RolesEnum.SUPER_ADMIN):
        this.router.navigate(['/dashboard/admin']);
        break;
      case user.roles.includes(RolesEnum.PROJECT_ADMIN) || user.roles.includes(RolesEnum.PROGRAM_ADMIN):
        this.router.navigate(['/dashboard/project']);
        break;
      default:
        this.router.navigate(['/unauthorized']);
        break;
    }
  }
}
