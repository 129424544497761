import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import { NgbAlert, NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { BehaviorSubject, Subject } from "rxjs";
import { debounceTime } from "rxjs/operators";
import { Project } from "app/core/services/project/project";
import { ProjectService } from "app/core/services/project/project.service";
import { ActivatedRoute } from "@angular/router";
import { AccountActionEvent } from "..";
import { AuthService } from "app/core/services/auth/auth.service";
import { LoaderService } from "../../../core/services/shared/loader.service";

@Component({
  selector: "app-project-list",
  templateUrl: "./project-list.component.html",
  styleUrls: ["./project-list.component.scss"],
})
export class ProjectListComponent implements OnInit {

  public projects: Project[] = [];
  projectToBeUpdated: Project | null;
  public projects$ = new BehaviorSubject<Project[]>([]);
  public modalRef: any;
  isUpdate: boolean = false;
  disableAllowed: boolean;
  _message = new Subject<string>();
  message = "";
  alertType = "";
  saving = false;
  confirming: boolean = false;
  modalTitle: string;
  projectStatus: any = [{
    name: 'Inactive',
    value: 0
  }, {
    name: 'Active',
    value: 1
  }, {
    name: 'Cancelled',
    value: 2
  }];
  confirmationMessage: string;
  confirmationHeader: string;
  accountEvent: AccountActionEvent;
  @ViewChild("projectModal") editModal: ElementRef;
  @ViewChild("selfClosingAlert", { static: false }) selfClosingAlert: NgbAlert;
  @ViewChild("confirmationModal") confirmModal: ElementRef;


  constructor(
    private _projectService: ProjectService,
    private _modalService: NgbModal,
    private _authService: AuthService,
    private _loaderService: LoaderService,
    private route: ActivatedRoute
  ) {
  }


  ngOnInit(): void {
    //fetch Data from resolver if admin
    this.route.data.subscribe(
      (data: any) => {
        this.projects = data.projects;
        this.projects$.next(this.projects);
      },
      (error) => {
        console.log(error);
      }
    );

    //config alert
    this._message.pipe(debounceTime(3000)).subscribe(() => {
      if (this.selfClosingAlert) {
        this.selfClosingAlert.close();
      }
    });
  }

  onSubmitEvent($event: Project) {
    if (this.isUpdate) {
      this.updateProject($event);
    } else {
      this.addProject($event);
    }
  }

  onAddProject() {
    this.isUpdate = false;
    this.modalTitle = "Add Project"

    this.modalRef = this._modalService.open(this.editModal, {
      scrollable: false,
      size: "lg",
      centered: false,
    });
  }

  refreshData() {
    this._projectService.getAll().subscribe({
      next: (project) => {
        this.projects = project;
        this.projects$.next(project)
      },
    });
  }

  addProject(user: Project): void {
    this._loaderService.showProgress.next(true);
    this._projectService.add(user).subscribe(
      () => {

        //close Modal
        this._modalService.dismissAll(this.editModal);
        this._loaderService.showProgress.next(false);

        //reload Data
        this.refreshData();

        //success notification
        this.alertType = "success";
        this._message.next(this.message);
        this.message = "Project added Successfully!";
        this.saving = false;

      },
      (error) => {
        console.log(error);

        this.saving = false;
        this._modalService.dismissAll(this.editModal);
        this._loaderService.showProgress.next(false);

        //error notification
        this.alertType = "danger";
        this._message.next(this.message);
        this.message = "Error Adding Project! please try again later";
      }
    );
  }


  onAccountEvent(event: AccountActionEvent) {
    this.accountEvent = event;
    switch (event.action) {
      case "enable":
        this.confirmationMessage = "Are you sure you want to activate Project Account?"
        this.confirmationHeader = "Activate Account ?"
        break;
      case "disable":
        this.confirmationMessage = "Are you sure you want to disable Project Account?"
        this.confirmationHeader = "Disable Project ?"
        break;
      case "delete":
        this.confirmationMessage = "Are you sure you want to delete Project Account?"
        this.confirmationHeader = "Delete Project ?"
        break;

      case "reset":
        this.confirmationMessage = "Are you sure you want to reset Project Password?"
        this.confirmationHeader = "Reset Project Password ?"
        break;
    }
    this._modalService.open(this.confirmModal, {
      scrollable: false,
      centered: true,

    });
  }


  onConfirmAction() {
    this._modalService.dismissAll(this.confirmModal);
    this.sendAccountAction();
  }

  sendAccountAction() {
    this.projectToBeUpdated = this.projects.find((project) => project.uuid === this.accountEvent.id);
    this._loaderService.showProgress.next(true);

    switch (this.accountEvent.action) {
      case "delete":
        this._projectService.delete(this.projectToBeUpdated.uuid).subscribe(
          () => {
            //reload Data
            this.refreshData();
            //show notification
            this.alertType = "success";
            this._message.next(this.message);
            this.message = "Project Deleted Successfully!";
            this._loaderService.showProgress.next(false);
          },
          (error) => {
            console.log(error);
            this._loaderService.showProgress.next(false);
            //error notification
            this.alertType = "danger";
            this._message.next(this.message);
            this.message =
              "Unable to process your request! please try again later";
          }
        );
        break;
    }
  }

  updateProject(user: Project): void {
    this._loaderService.showProgress.next(true);
    this._projectService.update(user, this.projectToBeUpdated.uuid).subscribe(
      (data) => {
        //patch Data
        this.refreshData();
        //close Modal
        this._modalService.dismissAll(this.editModal);
        this._loaderService.showProgress.next(false);
        //success notification
        this.alertType = "success";
        this._message.next(this.message);
        this.message = "Project Info Updated Successfully!";
        this.saving = false;

      },
      (error) => {
        console.log(error);
        this.saving = false;
        this._modalService.dismissAll(this.editModal);
        this._loaderService.showProgress.next(false);

        //error notification
        this.alertType = "danger";
        this._message.next(error.message);

      }
    );

  }

  onEditProject(uuid: string) {

    this.isUpdate = true;
    this.modalTitle = "Update Project"
    this.projectToBeUpdated = this.projects.find((user) => user.uuid == uuid);
    this.modalRef = this._modalService.open(this.editModal, {
      scrollable: false,
      size: "lg",
      centered: false,
    });

  }
}

