<div class="modal-header">
  <h5 class="modal-title mt-0">{{modalTitle}}</h5>
  <button (click)="activeModal.dismiss('Close click')" aria-label="Close" class="btn-close" type="button">
  </button>
</div>

<div class="modal-body ">

  <form (ngSubmit)="validSubmit()" [formGroup]="logicForm">
    <div *ngIf="hasParent" class="mb-3 ">
      <label>{{parentLabel}} <span class="text-danger"> *</span> </label>
      <ng-select formControlName="parentUuid" [items]="parentList" bindLabel="code"
                 bindValue="uuid"  placeholder="Select one" class="edit-input">
      </ng-select>
    </div>
    <div class="mb-3 ">
      <label>Code <span class="text-danger"> *</span> </label>
      <input type="text" class="form-control edit-input" formControlName="code"
             placeholder="Enter Code"
             [ngClass]="{'is-invalid' :(form.code.dirty || submit )  && form.code.errors}">
      <div *ngIf="(form.code.dirty || submit ) && form.code.errors" class="invalid-feedback">
        <span *ngIf="form.code.errors.required">Please provide a valid code.</span>
      </div>
    </div>
    <div class="mb-3 ">
      <label>Name <span class="text-danger"> *</span> </label>
      <textarea type="text" class="form-control edit-input"
                formControlName="name"
                placeholder="Enter Name"
                [ngClass]="{'is-invalid' :(form.name.dirty || submit )  && form.name.errors}"></textarea>

      <div *ngIf="(form.name.dirty || submit ) && form.name.errors" class="invalid-feedback">
        <span *ngIf="form.name.errors.required">Please provide a valid name.</span>
      </div>
    </div>

    <div class="d-flex flex-wrap gap-2">
      <button [disabled]="saving || !formChanged " class="btn btn-primary" type="submit">Save
        <span *ngIf="saving" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
      </button>
      <button type="reset" [disabled]="saving " class="btn btn-secondary waves-effect">
        Reset
      </button>
    </div>

  </form>

</div>
