import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable, of, throwError} from 'rxjs';
import {catchError, switchMap} from 'rxjs/operators';
import {environment} from 'environments/environment';
import {User} from '../user/user';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  private currentUserSubject: BehaviorSubject<User> = new BehaviorSubject<User>(new User());

  constructor(private _http: HttpClient) {
  }

  setAccessToken(token: string): void {
    localStorage.setItem('accessToken', token);
  }

  accessToken(): string {
    return localStorage.getItem('accessToken');
  }

  public currentUser(): User {
    return this.currentUserSubject.value;
  }

  public setUserSubject(user: User) {
    this.currentUserSubject.next(user);
  }

  isLoggedIn(): Observable<boolean> {
    if (this.isTokenPresent() && this.userDataExist()) {
      return of(true);
    } else if (this.isTokenPresent() && !this.userDataExist()) {
      return this.getUserData(localStorage.getItem("userId"))
        .pipe(switchMap(resp => {
            return of(true);
          }),
          catchError(err => {
            return of(false);
          })
        );
    } else {
      return of(false);
    }
  }

  isTokenPresent(): boolean {
    return !!this.accessToken();
  }

  userDataExist(): boolean {
    return Object.keys(this.currentUser()).length > 0;
  }

  login(credentials: any): Observable<any> {
    return this._http
      .post(environment.baseURL + '/auth', credentials)
      .pipe(
        catchError(this.handleError),
        switchMap((response: any) => {
          this.setAuthData(response);
          return of(JSON.parse(JSON.stringify(response.user)));

        })
      );
  }

  getUserData(userId: string): Observable<any> {
    return this._http
      .get(environment.baseURL + `/user/get/${userId}`,).pipe(
        catchError(this.handleError),
        switchMap((response: any) => {
          let user: User = response;
          localStorage.setItem("userId", user.userId);
          this.currentUserSubject.next(user);
          return of(response);
        })
      );
  }

  signOut(): Observable<any> {
    // Remove the access token from the local storage
    localStorage.removeItem('accessToken');
    this.currentUserSubject.next(null);
    // Return the observable
    return of(true);
  }

  private setAuthData(authResponse: any): void {
    // Store the access token in the local storage
    this.setAccessToken(authResponse.jwt);
    //store User Data
    let user: User = JSON.parse(JSON.stringify(authResponse.user));
    localStorage.setItem("userId", user.userId);
    this.currentUserSubject.next(user);
  }

  /**
   * @param error
   * @returns void
   */
  private handleError(err: any): Observable<any> {
    let status = err.status;
    if (status === 0) {
      return throwError('Unable to connect, Please check your connection and try again later'
      );
    } else if (status === 400) {

      return throwError("Password or username error!");
    } else {
      return throwError("Unable to sign you in please try again later!");
    }
  }
}
