import {Component, ElementRef, EventEmitter, Input, OnInit, Output, SimpleChanges, ViewChild} from '@angular/core';
import {BehaviorSubject, Observable, of, Subject} from "rxjs";
import {Extension, Project} from "../../../core/services/project/project";
import {FormBuilder, FormGroup} from "@angular/forms";
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {NgbDateCustomParserFormatter} from "../../../core/helpers/ngb-date-formatter";
import {LoaderService} from "../../../core/services/shared/loader.service";
import {ProjectService} from "../../../core/services/project/project.service";
import {debounceTime, delay, switchMap} from "rxjs/operators";

interface extensionSearchResult {
  extensions: Extension[];
  total: number;
}

interface State {
  page: number;
  pageSize: number;
  searchTerm: string;
  sortColumn: string;
  statusSearchTerm: string;
  startIndex: number;
  endIndex: number;
  totalRecords: number;
}

@Component({
  selector: 'app-extensions',
  templateUrl: './extensions.component.html',
  styleUrls: ['./extensions.component.scss']
})
export class ExtensionsComponent implements OnInit {


  public extensions$ = new BehaviorSubject<Extension[]>([]);

  @Input() extensions: Extension[];
  @Input() project: Project;
  @Output() refreshEvent: EventEmitter<any> = new EventEmitter<any>();
  @Output() errorEvent: EventEmitter<any> = new EventEmitter<any>();
  private _State: State;
  submit: boolean;
  extensionForm: FormGroup;
  modalRef: any;
  saving: boolean;
  isUpdate: boolean;
  formChanged: any | boolean;
  formInitialValue: any;
  private _search$ = new Subject<void>();
  @ViewChild("budgetFormModal") budgetModal: ElementRef;

  constructor(private fb: FormBuilder,
              private modalService: NgbModal,
              private dateFormatter: NgbDateCustomParserFormatter,
              private _loaderService: LoaderService,
              private projectService: ProjectService) {
  }

  ngOnInit(): void {
    this._State = {
      page: 1,
      pageSize: 5,
      searchTerm: "",
      statusSearchTerm: null,
      sortColumn: "",
      startIndex: 0,
      endIndex: 5,
      totalRecords: 0,
    };
    this._search$
      .pipe(
        debounceTime(100),
        switchMap(() => this._search()),
        delay(100),
      )
      .subscribe((result) => {
        this.extensions$.next(result.extensions);
      });

    this._search$.next();
    this.extensionForm = this.fb.group({
      reason: [null],
      addBudget: [null],
      newEndDate: [null],
      oldBudget: [null],
      oldEndDate: [null]
    })

    //subscribe to form change
    this.extensionForm.valueChanges.subscribe((value) => {
      this.formChanged = this.formInitialValue ? Object.keys(this.formInitialValue)
        .some(key => this.extensionForm.value[key] !==
          this.formInitialValue[key]) : true;
    });

  }


  get form() {
    return this.extensionForm.controls;
  }

  set pageSize(pageSize: number) {
    this._set({pageSize});
  }


  set searchTerm(searchTerm: string) {
    this._set({searchTerm});
    this._search$.next();
  }

  set statusSearchTerm(statusSearchTerm: string) {
    this._set({statusSearchTerm});
    this._search$.next();
  }

  private _set(patch: Partial<State>) {
    Object.assign(this._State, patch);
    this._search$.next();
  }

  get startIndex() {
    return this._State.startIndex;
  }

  get total$() {
    return this._State.totalRecords;
  }

  get endIndex() {
    return this._State.endIndex;
  }

  get totalRecords() {
    return this._State.totalRecords;
  }

  get page() {
    return this._State.page;
  }

  get pageSize() {
    return this._State.pageSize;
  }

  get searchTerm() {
    return this._State.searchTerm;
  }


  get statusSearchTerm() {
    return this._State.statusSearchTerm;
  }

  set page(page: number) {
    this._set({page});
  }

  _search(): Observable<extensionSearchResult> {

    const {page, searchTerm, statusSearchTerm} =
      this._State;
    // 1. sort
    let extensions = this.extensions;
    const total = extensions.length;
    // 3. paginate
    this._State.totalRecords = extensions.length;
    this._State.startIndex = (page - 1) * this.pageSize + 1;
    this._State.endIndex = (page - 1) * this.pageSize + this.pageSize;
    if (this.endIndex > this.totalRecords) {
      this._State.endIndex = this.totalRecords;
    }
    extensions = extensions.slice(this._State.startIndex - 1, this._State.endIndex);

    return of({extensions, total});
  }


  ngOnChanges(changes: SimpleChanges): void {
    this._search$.next();
  }

  onAddExtension() {
    this.extensionForm.reset()
    this.modalTitle = "Add Extension"
    this.isUpdate = false;
    this.modalRef = this.modalService
      .open(this.budgetModal, {
        scrollable: false,
        size: "md",
        centered: false,
      });
  }

  modalTitle: any;

  validSubmit() {
    this.submit = true;
    if (this.extensionForm.valid) {
      this.submitExtension();
    } else {
      console.log(this.extensionForm.value)
    }

  }

  submitExtension() {
    const formData = this.extensionForm.value;
    formData.newEndDate = this.dateFormatter.format(this.form.newEndDate.value)
    this._loaderService.showProgress.next(true);

    this.projectService.addExtension(this.project.uuid, formData)
      .subscribe(
        () => {
          this._loaderService.showProgress.next(false);
          this.refreshEvent.emit();
        },
        (error) => {
          this.saving = false;
          this.errorEvent.emit(error);
          this._loaderService.showProgress.next(false);

        }
      );

  }


}
