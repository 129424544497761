import {Injectable} from "@angular/core";
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot,} from "@angular/router";
import {Observable} from "rxjs";
import {map} from "rxjs/operators";
import {RolesEnum} from "app/shared/models/Roles";
import {AuthService} from "../services/auth/auth.service";

@Injectable({providedIn: "root"})
export class SuperAdminGuard implements CanActivate {
  constructor(
    private router: Router,
    private _authService: AuthService
  ) {
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {


    return this._authService.isLoggedIn()
      .pipe(
        map(loggedIn => {

          if (loggedIn) {
            if (this._authService.currentUser().roles.includes(RolesEnum.SUPER_ADMIN)) {
              return true;
            } else {
              console.log("here")
              this.router.navigate(["/unauthorized"],
                {
                  queryParams: {returnUrl: state.url},
                }
              );
              return false;
            }

          } else {
            this.router.navigate(['/account/login'], {queryParams: {returnUrl: state.url}});
            return false;
          }
        }));

  }
}
