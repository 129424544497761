<div class="mt-3 ">
  <div>
    <h5 class="text-primary mb-3">Indicators</h5>
    <div *ngIf="enableAdd" class="mb-3 ">
      <button (click)="onAdd()" class="btn btn-sm
     btn-outline-primary ">Add Indicator
      </button>
    </div>
  </div>
  <div class="table-responsive ">
    <table class="table table-sm table-hover">
      <thead>
      <tr>
        <th style="width: 15%">Code</th>
        <th style="width: 30%">Name</th>
        <th>Target</th>
        <th>measurement</th>
        <th>Actions</th>
      </tr>
      </thead>
      <tbody>
      <tr *ngFor="let indicator of indicators">
        <td>{{indicator.code}}</td>
        <td>{{indicator.name}}</td>
        <td>{{indicator.target}}</td>
        <td>{{indicator.measurement}}</td>
        <td (click)="$event.stopPropagation()" class="align-middle">
          <div class="btn-group">
            <button (click)="onEditIndicator(indicator)" class="btn btn-sm btn-outline-primary ">
              <i class="mdi mdi-pen "></i>
              Edit
            </button>

            <button
              (click)="onDeleteIndicator(indicator.uuid)"
              class="btn btn-sm btn-outline-danger ">
              <i class="mdi mdi-trash-can-outline "></i>
              Delete
            </button>
          </div>
        </td>
      </tr>
      </tbody>
    </table>
  </div>
  <!-- End table -->
</div>


<ng-template #indicatorFormModal let-modal>
  <div class="modal-header">
    <h5 class="modal-title mt-0">{{modalTitle}}</h5>
    <button (click)="modal.dismiss('Close click')" aria-label="Close" class="btn-close" type="button">
    </button>
  </div>

  <div class="modal-body ">

    <form (ngSubmit)="validSubmit()" [formGroup]="indicatorForm">

      <div class="row">
        <div class="mb-3 col-sm-6 ">
          <label>Code <span class="text-danger"> *</span> </label>
          <input type="text" class="form-control edit-input" formControlName="code"
                 placeholder="Enter code"
                 [ngClass]="{'is-invalid' :(form.code.dirty || submit )  && form.code.errors}">
          <div *ngIf="(form.code.dirty || submit ) && form.code.errors" class="invalid-feedback">
            <span *ngIf="form.code.errors.required">Please provide a valid code.</span>
          </div>

        </div>
        <div class="mb-3 col-sm-6">
          <label>Name <span class="text-danger"> *</span> </label>
          <textarea type="text" class="form-control edit-input" formControlName="name"
                    placeholder="Enter Name"
                    [ngClass]="{'is-invalid' :(form.name.dirty || submit )  && form.name.errors}"> </textarea>
          <div *ngIf="(form.name.dirty || submit ) && form.name.errors" class="invalid-feedback">
            <span *ngIf="form.name.errors.required">Please provide a valid name.</span>
          </div>

        </div>
      </div>

      <div class="row">
        <div class="mb-3 col-sm-6 ">
          <label>Baseline</label>
          <input type="text" class="form-control edit-input" formControlName="baseLine"
                 placeholder="Enter Baseline"
                 [ngClass]="{'is-invalid' :(form.baseLine.dirty || submit )  && form.baseLine.errors}">
          <div *ngIf="(form.baseLine.dirty || submit ) && form.baseLine.errors" class="invalid-feedback">
            <span *ngIf="form.baseLine.errors.required">Please provide a valid baseLine.</span>
          </div>
        </div>
        <div class="mb-3 col-sm-6">
          <label>Target <span class="text-danger"> *</span> </label>
          <input type="text" class="form-control edit-input" formControlName="target"
                 placeholder="Enter Target"
                 [ngClass]="{'is-invalid' :(form.target.dirty || submit )  && form.target.errors}">
          <div *ngIf="(form.target.dirty || submit ) && form.target.errors" class="invalid-feedback">
            <span *ngIf="form.target.errors.required">Please provide a valid target.</span>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="mb-3 col-sm-6 ">
          <label>Achieved </label>
          <input type="text" class="form-control edit-input" formControlName="achieved"
                 placeholder="Enter achieved"
                 [ngClass]="{'is-invalid' :(form.achieved.dirty || submit )  && form.achieved.errors}">
          <div *ngIf="(form.achieved.dirty || submit ) && form.achieved.errors" class="invalid-feedback">
            <span *ngIf="form.achieved.errors.required">Please provide a valid value.</span>
          </div>
        </div>
        <div class="mb-3 col-sm-6">
          <label>Measurement <span class="text-danger"> *</span> </label>
          <input type="text" class="form-control edit-input" formControlName="measurement"
                 placeholder="Enter measurement"
                 [ngClass]="{'is-invalid' :(form.measurement.dirty || submit )  && form.measurement.errors}">
          <div *ngIf="(form.measurement.dirty || submit ) && form.measurement.errors" class="invalid-feedback">
            <span *ngIf="form.measurement.errors.required">Please provide a valid measurement.</span>
          </div>

        </div>
      </div>

      <div class="row">

        <div class="mb-3 col-sm-6">
          <label>Assumption</label>
          <input type="text" class="form-control edit-input" formControlName="assumption"
                 placeholder="Enter assumption"
                 [ngClass]="{'is-invalid' :(form.assumption.dirty || submit )  && form.assumption.errors}">
          <div *ngIf="(form.assumption.dirty || submit ) && form.assumption.errors" class="invalid-feedback">
            <span *ngIf="form.assumption.errors.required">Please provide a valid assumption.</span>
          </div>

        </div>
      </div>

      <div class="d-flex flex-wrap gap-2">
        <button [disabled]="saving || !formChanged " class="btn btn-primary" type="submit">Save
          <span *ngIf="saving" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
        </button>
        <button type="reset" [disabled]="saving " class="btn btn-secondary waves-effect">
          Reset
        </button>
      </div>

    </form>

  </div>

</ng-template>
