<div class="table-responsive mx-auto col-sm-12">

  <div class="d-flex justify-content-between">
    <h5 class="text-primary">{{title}}</h5>
    <div class="mb-3 btn-group">
      <button
        *ngIf="logicType===Logic_type.RESULT"
        (click)="onAddActivity(Logic_type.ACTIVITY, true,logics.value,'Result')"
        class="btn btn-sm btn-outline-primary">
        <i class="mdi mdi-plus"></i>
        Add Activity
      </button>
      <button *ngIf="enableAdd"
              (click)="onAddInterventionLogic(logicType , hasParent,parentLogics.value, parentLabel)"
              class="btn btn-sm btn-outline-success">
        <i class="mdi mdi-plus"></i>
        {{this.addBtnName}}
      </button>
    </div>
  </div>

  <div *ngIf="logicType===Logic_type.RESULT" class="col-md-2">
    <label><i class="bx bx-filter-alt text-primary"></i> Specific Objective</label>
    <ng-select (change)="onResultFilter($event)" [(ngModel)]="soFilter" [clearable]="true"
               [items]="parentLogics.value" bindLabel="code"
               bindValue="uuid" class="edit-input" placeholder="Specific Objective">
    </ng-select>
  </div>

  <table class="table table-sm table-hover table-striped mt-3 w-100">
    <thead>
    <tr>
      <th style="width: 10%;">Code</th>
      <th>Name</th>
      <th class="text-end">Actions</th>
    </tr>
    </thead>
    <tbody>
    <ng-container *ngFor="let logic of filteredLogics | async">
      <tr>
        <td class="align-middle text-truncate ">
          {{logic.code}}
        </td>
        <td style="text-align: justify"
            class="align-middle mr-4">{{logic.name}}</td>
        <td (click)="$event.stopPropagation()">
          <div class="btn-group float-end">

            <button (click)="showIndicators(logic)" class="btn btn-sm btn-outline-info ">
              Indicators
            </button>

            <button (click)="showSubLogics(logic)" *ngIf="hasActivities" class="btn btn-sm btn-outline-warning ">
              Activities
            </button>

            <button
              (click)="onUpdateInterventionLogic(logic, logicType,  hasParent, parentLogics.value, parentLabel)"
              class="btn btn-sm btn-outline-primary ">
              Edit
            </button>

            <button (click)="onDeleteInterventionLogic(logic.uuid, logicType)"
                    class="btn btn-sm btn-outline-danger ">

              Delete
            </button>
          </div>

        </td>
      </tr>

      <!-- sublogic table row (hidden by default) -->
      <tr class="table-primary" *ngIf="logic.showSublogics && logic.subLogics.length>0">
        <td colspan="3">
          <table class="table table-sm table-hover w-80 sublogic-table ">
            <thead>
            <tr>
              <th style="width: 10%;">Code</th>
              <th>Name</th>
              <th class="text-end">Actions</th>
            </tr>
            </thead>
            <tbody>
            <tr *ngFor="let sublogic of logic.subLogics">
              <td class="align-middle text-truncate ">
                {{sublogic.code}}
              </td>
              <td style="text-align: justify"
                  class="align-middle mr-4">{{sublogic.name}}</td>
              <td (click)="$event.stopPropagation()">
                <div class="btn-group float-end">
                  <button
                    (click)="onUpdateInterventionLogic(sublogic, sublogic.logicType,  true,logics.value,'Result','Activity')"
                    class="btn btn-sm btn-outline-primary ">
                    Edit
                  </button>

                  <button (click)="onDeleteInterventionLogic(sublogic.uuid, sublogic.logicType)"
                          class="btn btn-sm btn-outline-danger ">

                    Delete
                  </button>
                </div>

              </td>
            </tr>
            </tbody>
          </table>
        </td>
      </tr>

      <!-- indicators table row (hidden by default) -->
      <tr class="table-info" *ngIf="logic.indicatorsVisible">
        <td colspan="3">
          <app-indicators
            [logic]="logic"
            [project]="project"
            [type]="logic.logicType"
            [enableAdd]="true"
            (refreshEvent)="refreshDataEvent.emit()"
            (errorEvent)="errorEvent.emit($event)"
            [indicators]="logic.indicators">
          </app-indicators>
        </td>
      </tr>

    </ng-container>

    </tbody>
  </table>

</div>
