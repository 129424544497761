import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {RouterModule} from '@angular/router';
import {
  NgbCollapseModule,
  NgbDatepickerModule,
  NgbDropdownModule,
  NgbModalModule,
  NgbNavModule,
  NgbPaginationModule,
  NgbTimepickerModule,
  NgbTypeaheadModule
} from '@ng-bootstrap/ng-bootstrap';
import {Ng2SmartTableModule} from 'ng2-smart-table';
import {NgSelectModule} from '@ng-select/ng-select';
import {NgxMaskModule} from 'ngx-mask';
import {UserTableComponent} from './user-table/user-table.component';
import {NgxDaterangepickerMd} from "ngx-daterangepicker-material";
import {PagetitleComponent} from "./pagetitle/PagetitleComponent";
import {ProjectTableComponent} from "./project-table/project-table.component";

@NgModule({
  declarations: [
    UserTableComponent,
    ProjectTableComponent,
    PagetitleComponent],
  imports: [
    NgbDropdownModule,
    NgbNavModule,
    NgxMaskModule,
    NgbModalModule,
    NgbPaginationModule,
    NgbTypeaheadModule,
    NgxDaterangepickerMd.forRoot(),
    NgbDatepickerModule,
    CommonModule,
    ReactiveFormsModule,
    RouterModule,
    FormsModule,
    NgSelectModule,
    NgbCollapseModule,
    NgbDatepickerModule,
    NgbTimepickerModule,
    NgbDropdownModule,
    Ng2SmartTableModule
  ],
  exports: [UserTableComponent,ProjectTableComponent,
    PagetitleComponent]
})
export class UIModule {
}
