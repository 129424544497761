<!-- Table -->
<div class="mt-3 col-sm-8">
  <div class="mb-3">
    <button (click)="onAddPlan()" class="btn btn-sm btn-outline-primary">Add Plan</button>
  </div>

  <div class="table-responsive ">
    <table class="table table-sm table-hover">
      <thead>
      <tr>
        <th sortable="lastName"> Name</th>
        <th sortable="status">Type</th>
        <th sortable="telephone">measurement</th>
        <th sortable="permission">status</th>
        <th style="width: 20%;">Actions</th>
      </tr>
      </thead>
      <tbody *ngFor="let plan of plans$ | async ;let i=index">
      <tr>

        <td class="align-middle text-truncate ">
          {{
          plan.name
          }}
        </td>

        <td class="align-middle text-truncate ">
          {{
          plan.type
          }}
        </td>


        <td class="align-middle text-truncate ">
          {{plan.measurement}}
        </td>

        <td class="align-middle text-truncate ">

        <span class="badge badge-soft-primary" *ngIf="plan.status === 0">
          INACTIVE
        </span>
          <span class="badge badge-soft-success" *ngIf="plan.status == 1">
          ACTIVE
        </span>
          <span class="badge badge-soft-danger" *ngIf="plan.status == 2">
          CANCELED
        </span>
        </td>


        <td (click)="$event.stopPropagation()" class="align-middle">
          <div class="btn-group">
            <button (click)="onEdit(plan.uuid)" class="btn btn-sm btn-outline-primary ">
              <i class="mdi mdi-pen "></i>
              Edit
            </button>

            <button (click)="onDelete(plan.uuid, 'delete')"
                    class="btn btn-sm btn-outline-danger ">
              <i class="mdi mdi-trash-can-outline "></i>
              Delete
            </button>
          </div>
        </td>
      </tr>


      </tbody>
    </table>
  </div>
  <!-- End table -->
  <div class="row justify-content-md-between align-items-md-center mt-2">
    <div class="col-sm-12 col-md-5">
      <div aria-live="polite" class="dataTables_info mb-2" id="tickets-table_info" role="status">Showing
        {{startIndex}} to
        {{endIndex}} of {{totalRecords}}
        entries
      </div>
    </div>
    <!-- Pagination -->
    <div class="col-sm-12 col-md-5">
      <div class="text-md-right float-md-end pagination-rounded">
        <ngb-pagination [(page)]="page" [collectionSize]="totalRecords" [pageSize]="pageSize">
        </ngb-pagination>
      </div>
    </div>
    <!-- End Pagination -->
  </div>
</div>

<ng-template #planModal let-modal>
  <div class="modal-header">
    <h5 class="modal-title mt-0">Add Plan</h5>
    <button (click)="modal.dismiss('Cross click')" aria-hidden="true" class="btn-close" type="button"></button>
  </div>
  <div class="modal-body">
    <form class="needs-validation" (ngSubmit)="validSubmit()" [formGroup]="planForm">

      <div class="row ">
        <div class="mb-3 col-md-6">
          <label>Name <span class="text-danger"> *</span></label>
          <input type="text" class="form-control edit-input" formControlName="name"
                 placeholder="Enter Name"
                 [ngClass]="{'is-invalid' :(form.name.dirty || submit ) && form.name.errors }">
          <div *ngIf=" form.name.errors && (form.name.dirty || submit )" class="invalid-feedback">
            <span *ngIf="form.name.errors.required">Please provide a valid name.</span>
          </div>
        </div>

        <div class="col-sm-6">
          <div class="mb-3 ">
            <label>Status <span class="text-danger"> *</span> </label>
            <ng-select [clearable]="false" [items]="projectStatus"
                       [ngClass]="{'is-invalid' :submit && form.status.errors}"
                       bindLabel="name" bindValue="value" formControlName="status">
            </ng-select>

            <div *ngIf="(form.status.dirty || submit) && form.status.errors" class="invalid-feedback">
              <span *ngIf="form.status.errors">Please choose a valid status.</span>
            </div>
          </div>

        </div>

      </div>
      <div class="row ">

        <div class="mb-3 col-sm-6">
          <label> Type<span class="text-danger"> *</span> </label>
          <input type="text" class="form-control date-cursor " formControlName="type"
                 (click)="ldd.toggle()" ngbDatepicker #ldd="ngbDatepicker"
                 autocomplete="off"
                 placeholder="Enter type"
                 [ngClass]="{'is-invalid' :(form.type.dirty || submit) && form.type.errors}">
          <div *ngIf="(form.type.dirty || submit) && form.type.errors" class="invalid-feedback">
            <span *ngIf="form.type.errors">Please provide a valid date.</span>
          </div>
        </div>
        <div class="mb-3 col-md-6">
          <label> Measurement <span class="text-danger"> *</span></label>
          <input currencyMask type="text" class="form-control edit-input" formControlName="measurement"
                 placeholder="Enter measurement"
                 [ngClass]="{'is-invalid' :(form.measurement.dirty || submit )  && form.measurement.errors}">
          <div *ngIf="(form.measurement.dirty || submit ) && form.measurement.errors" class="invalid-feedback">
            <span *ngIf="form.measurement.errors.required">Please provide a valid budget.</span>
          </div>
        </div>


      </div>


      <div class="d-flex flex-wrap gap-2">
        <button [disabled]="saving || !formChanged " class="btn btn-primary" type="submit">Save
          <span *ngIf="saving" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
        </button>
        <button type="reset" [disabled]="saving " class="btn btn-secondary waves-effect">
          Reset
        </button>
      </div>

    </form>


  </div>
  <div class="modal-footer">
    <button (click)="onNewPlan()" class="btn btn-primary" type="button">Yes</button>
    <button (click)="modal.dismiss('Cross click')" class="btn btn-secondary" data-dismiss="modal"
            type="button">Cancel
    </button>
  </div>
</ng-template>



