<div class="mt-3 ">
  <div class="mb-3">

  </div>
  <div class="d-flex  justify-content-between">
    <h5 class="text-primary">Budget Utilization</h5>
    <div class="">
      <button (click)="onAddUtilization()" class="btn btn-sm btn-outline-success">
        <i class="mdi mdi-plus"></i>
        Add Utilization
      </button>
    </div>

  </div>
  <div class="col-sm-3 mb-3">
    <label><i class="bx bx-filter-alt text-primary"></i>Budget</label>
    <ng-select [(ngModel)]="selectedBudget" [clearable]="true" (change)="onBudgetSelected($event)" [items]="budgetList"
      bindLabel="code" bindValue="uuid" class="edit-input" placeholder="Select Budget">
    </ng-select>
  </div>
  <div class="table-responsive ">
    <table class="table table-sm table-hover table-bordered table-striped">
      <thead>
        <tr>
          <th>Expenditure(Birr)</th>
          <th>Expenditure(USD)</th>
          <th>Date</th>
          <th style="width: 20%;">Remark</th>
          <th class="text-end" style="width: 20%;">Actions</th>
        </tr>
      </thead>
      <!--<tbody *ngFor="let budget of budgets$ | async ;let i=index"> -->
      <tbody>

        <ng-container *ngFor="let utilization of utilization$|async">
          <tr>

            <td class="align-middle text-truncate text-end">
              {{
              utilization.expenditureInBirr |currency:'ETB'
              }}
            </td>
            <td class="align-middle text-truncate  text-end">
              {{
              utilization.expenditureInUSD |currency
              }}
            </td>

            <td class="align-middle ">
              {{
              utilization.expenditureDate
              }}
            </td>
            <td class="align-middle text-truncate">
              {{
              utilization.remark
              }}
            </td>

            <td (click)="$event.stopPropagation()" class="align-middle">
              <div class="btn-group float-end">


                <button (click)="onEdit(utilization)" class="btn btn-sm btn-outline-primary ">
                  <i class="mdi mdi-pen "></i>
                  Edit
                </button>


                <button (click)="onDelete(utilization.uuid)" class="btn btn-sm btn-outline-danger ">
                  <i class="mdi mdi-trash-can-outline "></i>
                  Delete
                </button>
              </div>
            </td>
          </tr>

        </ng-container>
      </tbody>
    </table>
  </div>
  <!-- End table -->
  <div class="row justify-content-md-between align-items-md-center mt-2">
    <div class="col-sm-12 col-md-5">
      <div aria-live="polite" class="dataTables_info mb-2" id="tickets-table_info" role="status">Showing
        {{startIndex}} to
        {{endIndex}} of {{totalRecords}}
        entries
      </div>
    </div>
    <!-- Pagination -->
    <div class="col-sm-12 col-md-5">
      <div class="text-md-right float-md-end pagination-rounded">
        <ngb-pagination [(page)]="page" [collectionSize]="totalRecords" [pageSize]="pageSize">
        </ngb-pagination>
      </div>
    </div>
    <!-- End Pagination -->
  </div>
</div>


<ng-template #utilizationFormModal let-modal>
  <div class="modal-header">
    <h5 class="modal-title mt-0">{{modalTitle}}</h5>
    <button (click)="modal.dismiss('Close click')" aria-label="Close" class="btn-close" type="button">
    </button>
  </div>

  <div class="modal-body ">

    <form (ngSubmit)="validSubmit()" [formGroup]="utilizationForm">
      <div class="row ">
        <div class="col-sm-6 mb-3">
          <label><i class="bx bx-filter-alt text-primary"></i>Budget</label>
          <ng-select [clearable]="true" formControlName="budgetUuid" [items]="budgetList" bindLabel="code"
            bindValue="uuid" class="edit-input" placeholder="Select Budget">
          </ng-select>
        </div>
        <div class="mb-3 col-sm-6">
          <label>Expenditure Date<span class="text-danger"> *</span> </label>
          <input type="text" class="form-control date-cursor " formControlName="expenditureDate" (click)="ldd.toggle()"
            ngbDatepicker #ldd="ngbDatepicker" autocomplete="off" placeholder="Enter Date"
            [ngClass]="{'is-invalid' :(form.expenditureDate.dirty || submit) && form.expenditureDate.errors}">
          <div *ngIf="(form.expenditureDate.dirty || submit) && form.expenditureDate.errors" class="invalid-feedback">
            <span *ngIf="form.expenditureDate.errors">Please provide a valid date.</span>
          </div>
        </div>

      </div>

      <div class="row">

        <div class="mb-3 col-sm-6 ">
          <label>Expenditure (ETB) <span class="text-danger"> *</span></label>
          <input  type="text" class="form-control edit-input" formControlName="expenditureInBirr"
            placeholder="Enter budget amount"
            [ngClass]="{'is-invalid' :(form.expenditureInBirr.dirty || submit )  && form.expenditureInBirr.errors}">
          <div *ngIf="(form.expenditureInBirr.dirty || submit ) && form.expenditureInBirr.errors"
            class="invalid-feedback">
            <span *ngIf="form.expenditureInBirr.errors.required">Please provide a valid budget.</span>
          </div>
        </div>
        <div class="mb-3 col-sm-6 ">
          <label>Remark <span class="text-danger"> *</span></label>
          <textarea type="text" class="form-control edit-input" formControlName="remark" placeholder="Enter remark"
            [ngClass]="{'is-invalid' :(form.remark.dirty || submit )  && form.remark.errors}"></textarea>
          <div *ngIf="(form.remark.dirty || submit ) && form.remark.errors" class="invalid-feedback">
            <span *ngIf="form.remark.errors.required">Please provide a valid remark.</span>
          </div>
        </div>

      </div>


      <div class="d-flex flex-wrap gap-2">
        <button [disabled]="saving || !formChanged " class="btn btn-primary" type="submit">Save
          <span *ngIf="saving" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
        </button>
        <button type="reset" [disabled]="saving " class="btn btn-secondary waves-effect">
          Reset
        </button>
      </div>

    </form>

  </div>

</ng-template>