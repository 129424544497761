import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { NgModule } from "@angular/core";
import { HTTP_INTERCEPTORS, HttpClientModule, } from "@angular/common/http";

import {
  NgbAccordionModule,
  NgbActiveModal,
  NgbModule,
  NgbNavModule,
  NgbTooltipModule,
} from "@ng-bootstrap/ng-bootstrap";
import { CarouselModule } from "ngx-owl-carousel-o";
import { ScrollToModule } from "@nicky-lenaers/ngx-scroll-to";
import { LayoutsModule } from "./layouts/layouts.module";
import { SharedModule } from "./shared/shared.module";
import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { ErrorInterceptor } from "./core/helpers/error.interceptor";
import { JwtInterceptor } from "./core/helpers/jwt.interceptor";
import { PagesModule } from "./modules/modules.module";
import { NgProgressModule } from 'ngx-progressbar'
import { LoaderComponent } from "./shared/ui/loader/loader.component";
import { NgbDateCustomParserFormatter } from "./core/helpers/ngb-date-formatter";
import { SettingsComponent } from "./modules/settings/settings.component";
import { UIModule } from "./shared/ui/ui.module";
import { ReactiveFormsModule } from "@angular/forms";


@NgModule({
  declarations: [AppComponent, LoaderComponent, SettingsComponent],

  imports: [

    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    LayoutsModule,
    NgProgressModule,
    AppRoutingModule,
    CarouselModule,
    NgbAccordionModule,
    NgbNavModule,
    UIModule,
    ReactiveFormsModule,
    NgbTooltipModule,
    ScrollToModule.forRoot(),
    PagesModule,
    SharedModule,
    NgbModule,
  ],
  bootstrap: [AppComponent],
  providers: [
    NgbDateCustomParserFormatter,
    NgbActiveModal,
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },


  ],
})
export class AppModule {
}
