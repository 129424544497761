<div class="container-fluid mx-auto col-sm-12 ">

  <div class="sticky-sec">

    <app-page-title [breadcrumbItems]="breadCrumbItems" title="PROJECT DETAILS">
    </app-page-title>
    <ngb-alert #selfClosingAlert (closed)="message = ''" *ngIf="message" [dismissible]="false" [type]="alertType">{{
      message
      }}
    </ngb-alert>

    <div class="card radius-10 border-start shadow-lg  border-0 border-1  border-primary ">
      <div class="card-body">

        <div class="d-flex justify-content-between border-bottom p-3">
          <div class="d-flex col-sm-10">
            <div class="flex-grow-1admi">
              <h5 class="fw-semibold">{{project.name}}
                <i [ngbTooltip]="project.description"
                   class="mdi mdi-information text-info "
                   placement="bottom"></i>
              </h5>

            </div>
          </div>
        </div>
        <ul #nav="ngbNav" [activeId]="1" class="nav nav-tabs-custom " ngbNav>

          <li [destroyOnHide]="false" [ngbNavItem]="1">
            <a ngbNavLink>Details</a>
            <ng-template ngbNavContent>
              <div class="row ">
                <div class="col-sm-6">

                  <div class="card radius-10   border-0 border-1  border-primary ">
                    <div class="card-body">

                      <div class="table-responsive ">
                        <h5 class="text-primary">Basic</h5>
                        <table class="table table-sm table-nowrap mb-0">
                          <tbody>
                          <tr>
                            <th scope="row">Proect UUID :</th>
                            <td>{{project.uuid}}</td>
                          </tr>
                          <tr>
                            <th scope="row">Start Date :</th>
                            <td>{{project.startDate}}</td>
                          </tr>
                          <tr class="role-span">
                            <th scope="row">End Date :</th>
                            <td>{{project.endDate}}</td>
                          </tr>
                          <tr class="role-span">
                            <th scope="row">Dashboard Id :</th>
                            <td>{{project.dashboardId}}</td>
                          </tr>
                          <tr class="role-span">
                            <th scope="row">Public Dashboard Id :</th>
                            <td>{{project.dashboardPublicId}}</td>
                          </tr>
                          <tr>
                            <th scope="row">Total Budget :</th>
                            <td>{{project.totalBudget | currency}}</td>
                          </tr>
                          <tr>
                            <th scope="row">Status</th>
                            <td
                              [ngClass]="{'bg-light':project.status===Project_status.NOT_STARTED,
             'bg-self':project.status===Project_status.IN_PROGRESS,
             'bg-accepted':project.status===Project_status.COMPLETED,
              'bg-eval':project.status===Project_status.MOVED,
              'bg-comp-rejected':project.status===Project_status.CANCELED,
              'bg-manager':project.status===Project_status.SUSPENDED
                          }"

                            >{{project.status}}</td>
                          </tr>

                          </tbody>
                        </table>
                      </div>

                    </div>
                  </div>
                </div>
                <div class="col-sm-6">
                  <div class="card radius-10 border-0 border-1  border-primary ">
                    <div class="card-body">

                      <app-intervention-logic-component
                        (errorEvent)="showError($event)"
                        (refreshDataEvent)="refreshData()"
                        [logicType]="Logic_type.GENERAL_OBJECTIVE"
                        [logics]="generalObjectives"
                        [project]="project"
                        [title]="'General Objective'"
                      ></app-intervention-logic-component>

                    </div>
                  </div>
                </div>
              </div>
              <div class="card radius-10   border-0 border-1  border-primary ">
                <div class="card-body">

                  <app-intervention-logic-component
                    (errorEvent)="showError($event)"
                    (refreshDataEvent)="refreshData()"
                    [logicType]="Logic_type.SPECIFIC_OBJECTIVE"
                    [logics]="specificObjectives"
                    [project]="project"
                    [title]="'Specific Objective'"
                  ></app-intervention-logic-component>

                </div>
              </div>
              <div class="card radius-10   border-0 border-1  border-primary ">
                <div class="card-body">
                  <app-intervention-logic-component
                    (errorEvent)="showError($event)"
                    (refreshDataEvent)="refreshData()"
                    [hasActivities]="true"
                    [hasParent]="true"
                    [logicType]="Logic_type.RESULT"
                    [logics]="results"
                    [parentLabel]="'Specific Objective'"
                    [parentLogics]="specificObjectives"
                    [project]="project"
                    [title]="'Result'"
                  ></app-intervention-logic-component>

                </div>
              </div>
            </ng-template>
          </li>
          <li [destroyOnHide]="false" [ngbNavItem]="2">
            <a ngbNavLink>DashBoard</a>
            <ng-template ngbNavContent>

              <iframe [src]="getDashboardUrl()" allowtransparency frameborder="0"
                      height="600"
                      width="1400"></iframe>

            </ng-template>
          </li>
          <li [destroyOnHide]="false" [ngbNavItem]="5">
            <a ngbNavLink>Budget</a>
            <ng-template ngbNavContent>
              <div class="card radius-10   border-0 border-1  border-primary ">
                <div class="card-body">
                  <app-project-budgets
                    (errorEvent)="showError($event)"
                    (refreshEvent)="refreshData()"
                    [budgets]="budgets"
                    [project]="project"></app-project-budgets>
                </div>
              </div>

              <div class="card radius-10   border-0 border-1  border-primary ">
                <div class="card-body">
                  <app-budget-utilization
                    (errorEvent)="showError($event)"
                    (refreshEvent)="refreshData()"
                    [budgetList]="budgets"
                    [project]="project">
                  </app-budget-utilization>

                </div>
              </div>
            </ng-template>
          </li>
          <li [destroyOnHide]="false" [ngbNavItem]="6">
            <a ngbNavLink>Indicators</a>
            <ng-template ngbNavContent>
              <div class="card radius-10   border-0 border-1  border-primary ">
                <div class="card-body">
                  <!--indicators list -->
                  <app-indicators
                    (errorEvent)="showError($event)"
                    (refreshEvent)="refreshData()"
                    [indicators]="project.indicators"
                    [project]="project"
                  ></app-indicators>

                </div>
              </div>
            </ng-template>
          </li>
          <li [destroyOnHide]="false" [ngbNavItem]="7">
            <a ngbNavLink>Activities</a>
            <ng-template ngbNavContent>
              <div class="card radius-10   border-0 border-1  border-primary ">
                <div class="card-body">
                  <app-intervention-logic-component
                    (errorEvent)="showError($event)"
                    (refreshDataEvent)="refreshData()"
                    [enableAdd]="false"
                    [hasParent]="false"
                    [logicType]="Logic_type.ACTIVITY"
                    [logics]="activities"
                    [project]="project"
                    [title]="'Activity'"
                  ></app-intervention-logic-component>
                </div>
              </div>
            </ng-template>
          </li>
          <li [destroyOnHide]="false" [ngbNavItem]="8">
            <a ngbNavLink>Extensions</a>
            <ng-template ngbNavContent>
              <div class="card radius-10   border-0 border-1  border-primary col-sm-8 ">
                <div class="card-body">
                  <app-extensions
                    (errorEvent)="showError($event)"
                    (refreshEvent)="refreshData()"
                    [extensions]="project.extensions"
                    [project]="project">
                  </app-extensions>
                </div>
              </div>
            </ng-template>
          </li>
          <li [destroyOnHide]="false" [ngbNavItem]="9">
            <a ngbNavLink>Database</a>
            <ng-template ngbNavContent>
              <div class="card radius-10 col-sm-4  border-0 border-1  border-primary ">
                <div class="card-body ">
                      <label>Upload Survey File</label>
                      <input #fileInput class="form-control" type="file" (change)="onFileSelected($event)" accept=".xlsx, .xls" />
                  <button (click)="uploadFile()" [disabled]="uploading" class="btn btn-primary mt-2" type="submit">Upload
                    <span *ngIf="uploading" aria-hidden="true" class="spinner-border spinner-border-sm"
                          role="status"></span>
                  </button>

                </div>
              </div>
            </ng-template>
          </li>

        </ul>
      </div>
    </div>
    <div [ngbNavOutlet]="nav" class=""></div>

  </div>

</div>
