<div class="mt-3 ">
  <div class="mb-3">

  </div>
  <div class="d-flex  justify-content-between">
    <h5 class="text-primary mb-3">Extensions</h5>
    <div class="">
      <button (click)="onAddExtension()" class="btn btn-sm btn-outline-success">
        <i class="mdi mdi-plus"></i>
        Add Extension
      </button>
    </div>

  </div>

  <div class="table-responsive ">
    <table class="table table-sm table-hover table-striped">
      <thead>
      <tr>
        <th>End Date</th>
        <th style="width: 40%;">Reason</th>
        <th>Budget Added (USD)</th>
      </tr>
      </thead>
      <!--<tbody *ngFor="let budget of budgets$ | async ;let i=index"> -->
      <tbody>

      <ng-container *ngFor="let extension of extensions$|async">
        <tr>

          <td class="align-middle text-truncate ">
            {{
            extension.newEndDate
            }}
          </td>
          <td class="align-middle  ">
            {{
            extension.reason
            }}
          </td>

          <td class="align-middle text-end">
            {{
            extension.addBudget | currency
            }}
          </td>
        </tr>

      </ng-container>
      </tbody>
    </table>
  </div>
  <!-- End table -->
  <div class="row justify-content-md-between align-items-md-center mt-2">
    <div class="col-sm-12 col-md-5">
      <div aria-live="polite" class="dataTables_info mb-2" id="tickets-table_info" role="status">Showing
        {{startIndex}} to
        {{endIndex}} of {{totalRecords}}
        entries
      </div>
    </div>
    <!-- Pagination -->
    <div class="col-sm-12 col-md-5">
      <div class="text-md-right float-md-end pagination-rounded">
        <ngb-pagination [(page)]="page" [collectionSize]="totalRecords" [pageSize]="pageSize">
        </ngb-pagination>
      </div>
    </div>
    <!-- End Pagination -->
  </div>
</div>

<ng-template #budgetFormModal let-modal>
  <div class="modal-header">
    <h5 class="modal-title mt-0">{{modalTitle}}</h5>
    <button (click)="modal.dismiss('Close click')" aria-label="Close" class="btn-close" type="button">
    </button>
  </div>

  <div class="modal-body ">

    <form (ngSubmit)="validSubmit()" [formGroup]="extensionForm">

      <div class="row">
        <div class="mb-3 col-sm-6">
          <label>Updated End Date<span class="text-danger"> *</span> </label>
          <input type="text" class="form-control date-cursor " formControlName="newEndDate"
                 (click)="ldd.toggle()" ngbDatepicker #ldd="ngbDatepicker"
                 autocomplete="off"
                 placeholder="Enter  Date"
                 [ngClass]="{'is-invalid' :(form.newEndDate.dirty || submit) && form.newEndDate.errors}">
          <div *ngIf="(form.newEndDate.dirty || submit) && form.newEndDate.errors" class="invalid-feedback">
            <span *ngIf="form.newEndDate.errors">Please provide a valid date.</span>
          </div>
        </div>
        <div class="mb-3 col-sm-6 ">
          <label>Budget Added (USD) <span class="text-danger"> *</span></label>
          <input currencyMask type="text" class="form-control edit-input" formControlName="addBudget"
                 placeholder="Enter budget amount"
                 [ngClass]="{'is-invalid' :(form.addBudget.dirty || submit )  && form.addBudget.errors}">
          <div *ngIf="(form.addBudget.dirty || submit ) && form.addBudget.errors" class="invalid-feedback">
            <span *ngIf="form.addBudget.errors.required">Please provide a valid budget.</span>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="mb-3 col-sm-6">
          <label>Reason <span class="text-danger"> *</span> </label>
          <textarea type="text" class="form-control edit-input" formControlName="reason"
                    placeholder="Enter extension reason"
                    [ngClass]="{'is-invalid' :(form.reason.dirty || submit )  && form.reason.errors}"> </textarea>
          <div *ngIf="(form.reason.dirty || submit ) && form.reason.errors"
               class="invalid-feedback">
            <span *ngIf="form.reason.errors.required">Please provide a valid reason.</span>
          </div>

        </div>
      </div>

      <div class="d-flex flex-wrap gap-2">
        <button [disabled]="saving || !formChanged " class="btn btn-primary" type="submit">Save
          <span *ngIf="saving" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
        </button>
        <button type="reset" [disabled]="saving " class="btn btn-secondary waves-effect">
          Reset
        </button>
      </div>

    </form>

  </div>

</ng-template>



