import {AbstractControl, ValidatorFn} from "@angular/forms";

export default class Validation {
  static match(controlName: string, checkControlName: string): ValidatorFn {
    return (controls: AbstractControl) => {
      const control = controls.get(controlName);
      const checkControl = controls.get(checkControlName);
      if (checkControl?.errors && !checkControl.errors["matching"]) {
        return null;
      }
      if (control?.value !== checkControl?.value) {
        controls.get(checkControlName)?.setErrors({matching: true});
        console.log(
          'macthing issue'
        );

        return {matching: true};
      } else {
        return null;
      }
    };
  }
}


export class DateHelper {

  static getDateFromString(stringDate: string) {
    const [year, month, day] = stringDate.split('-');
    const date = new Date(Number(year), Number(month), Number(day));
    return {day: date.getDate(), month: date.getMonth(), year: date.getFullYear()};
  }
}


