<form class="needs-validation" (ngSubmit)="validSubmit()" [formGroup]="projectForm">

  <div class="row ">
    <div class="mb-3 col-md-6">
      <label>Name <span class="text-danger"> *</span></label>
      <textarea type="text" class="form-control edit-input" formControlName="name"
                placeholder="Enter Name"
                [ngClass]="{'is-invalid' :(form.name.dirty || submit ) && form.name.errors }"> </textarea>
      <div *ngIf=" form.name.errors && (form.name.dirty || submit )" class="invalid-feedback">
        <span *ngIf="form.name.errors.required">Please provide a valid name.</span>
      </div>
    </div>
    <div class="mb-3 col-md-6">
      <label> Description <span class="text-danger"> *</span></label>
      <textarea style="overflow: visible" type="text" class="form-control edit-input" formControlName="description"
                placeholder="Enter Description"
                [ngClass]="{'is-invalid' :(form.description.dirty || submit )  && form.description.errors
         }"> </textarea>
      <div *ngIf="(form.description.dirty || submit ) && form.description.errors" class="invalid-feedback">
        <span *ngIf="form.description.errors.required">Please provide a valid description.</span>
      </div>
    </div>

  </div>
  <div class="row ">

    <div class="mb-3 col-sm-6">
      <label> Start Date<span class="text-danger"> *</span> </label>
      <input type="text" class="form-control date-cursor " formControlName="startDate"
             (click)="ldd.toggle()" ngbDatepicker #ldd="ngbDatepicker"
             autocomplete="off"
             placeholder="Enter Start Date"
             [ngClass]="{'is-invalid' :(form.startDate.dirty || submit) && form.startDate.errors}">
      <div *ngIf="(form.startDate.dirty || submit) && form.startDate.errors" class="invalid-feedback">
        <span *ngIf="form.startDate.errors">Please provide a valid date.</span>
      </div>
    </div>

    <div class="mb-3 col-sm-6">
      <label> End Date<span class="text-danger"> *</span> </label>
      <input type="text" class="form-control date-cursor"
             (click)="edd.toggle()" ngbDatepicker #edd="ngbDatepicker"
             autocomplete="off" formControlName="endDate" placeholder="Enter End Date"
             [ngClass]="{'is-invalid' :(form.endDate.dirty || submit) && form.endDate.errors }">
      <div *ngIf="(form.endDate.dirty || submit) && form.endDate.errors" class="invalid-feedback">
        <span *ngIf="form.endDate.errors">Please provide a valid date.</span>
      </div>
    </div>

  </div>

  <div class="row ">
    <div class="mb-3 col-md-6">
      <label> Total Budget <span class="text-danger"> *</span></label>
      <input currencyMask type="text" class="form-control edit-input" formControlName="totalBudget"
             placeholder="Enter Total Budget"
             [ngClass]="{'is-invalid' :(form.totalBudget.dirty || submit )  && form.totalBudget.errors}">
      <div *ngIf="(form.totalBudget.dirty || submit ) && form.totalBudget.errors" class="invalid-feedback">
        <span *ngIf="form.totalBudget.errors.required">Please provide a valid budget.</span>
      </div>
    </div>

    <div class="col-sm-6">
      <div class="mb-3 ">
        <label>Status <span class="text-danger"> *</span> </label>
        <ng-select [clearable]="false" [items]="projectStatus"
                   [ngClass]="{'is-invalid' :submit && form.status.errors}"
                   bindLabel="name" bindValue="name" formControlName="status">
        </ng-select>

        <div *ngIf="(form.status.dirty || submit) && form.status.errors" class="invalid-feedback">
          <span *ngIf="form.status.errors">Please choose a valid status.</span>
        </div>
      </div>

    </div>

  </div>
  <div class="row ">

    <div class="mb-3 col-sm-6">
      <label> Dashboard Id<span class="text-danger"> *</span> </label>
      <input type="text" class="form-control date-cursor " formControlName="dashboardId"
             autocomplete="off"
             placeholder="Enter Metabase Id"
             [ngClass]="{'is-invalid' :(form.dashboardId.dirty || submit) && form.dashboardId.errors}">
      <div *ngIf="(form.dashboardId.dirty || submit) && form.dashboardId.errors" class="invalid-feedback">
        <span *ngIf="form.dashboardId.errors">Please provide a valid Id.</span>
      </div>
    </div>


    <div class="mb-3 col-sm-6">
      <label> Dashboard Public Id<span class="text-danger"> *</span> </label>
      <input type="text" class="form-control date-cursor " formControlName="dashboardPublicId"
             autocomplete="off"
             placeholder="Enter Metabase  Id"
             [ngClass]="{'is-invalid' :(form.dashboardPublicId.dirty || submit) && form.dashboardPublicId.errors}">
      <div *ngIf="(form.dashboardPublicId.dirty || submit) && form.dashboardPublicId.errors" class="invalid-feedback">
        <span *ngIf="form.dashboardPublicId.errors">Please provide a valid Id.</span>
      </div>
    </div>
  </div>

  <div class="d-flex flex-wrap gap-2">
    <button [disabled]="saving || !formChanged " class="btn btn-primary" type="submit">{{actionBtnName}}
      <span *ngIf="saving" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
    </button>
    <button type="reset" [disabled]="saving " class="btn btn-secondary waves-effect">
      Reset
    </button>
  </div>

</form>
