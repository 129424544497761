import { Component, ElementRef, EventEmitter, Input, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { BehaviorSubject, Observable, of, Subject } from "rxjs";
import { Budget, Utilization } from "../project-detail/budget";
import { Project } from "../../../core/services/project/project";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { NgbDateCustomParserFormatter } from "../../../core/helpers/ngb-date-formatter";
import { LoaderService } from "../../../core/services/shared/loader.service";
import { ProjectService } from "../../../core/services/project/project.service";
import { debounceTime, delay, switchMap } from "rxjs/operators";
import { DateHelper } from "../../../core/helpers/Util";

interface utilizationSearchResult {
  utilization: Utilization[];
  total: number;
}

interface State {
  page: number;
  pageSize: number;
  searchTerm: string;
  sortColumn: string;
  statusSearchTerm: string;
  startIndex: number;
  endIndex: number;
  totalRecords: number;
}

@Component({
  selector: 'app-budget-utilization',
  templateUrl: './budget-utilization.component.html',
  styleUrls: ['./budget-utilization.component.scss']
})
export class BudgetUtilizationComponent implements OnInit {

  public utilization$ = new BehaviorSubject<Utilization[]>([]);

  @Input() utilization: Utilization[];
  @Input() project: Project;
  @Input() budgetList: Budget[];
  @Output() refreshEvent: EventEmitter<any> = new EventEmitter<any>();
  @Output() errorEvent: EventEmitter<any> = new EventEmitter<any>();
  private _State: State;
  submit: boolean;
  utilizationForm: FormGroup;
  modalRef: any;
  saving: boolean;
  isUpdate: boolean;
  formChanged: any | boolean;
  utilizationForUpdate: Utilization;
  formInitialValue: any;
  private _search$ = new Subject<void>();
  @ViewChild("utilizationFormModal") utilizationModal: ElementRef;

  constructor(private fb: FormBuilder,
    private modalService: NgbModal,
    private dateFormatter: NgbDateCustomParserFormatter,
    private _loaderService: LoaderService,
    private projectService: ProjectService) {
  }

  ngOnInit(): void {
    this._State = {
      page: 1,
      pageSize: 5,
      searchTerm: "",
      statusSearchTerm: null,
      sortColumn: "",
      startIndex: 0,
      endIndex: 5,
      totalRecords: 0,
    };
    this._search$
      .pipe(
        debounceTime(100),
        switchMap(() => this._search()),
        delay(100),
      )
      .subscribe((result) => {
        this.utilization$.next(result.utilization);
      });

    this._search$.next();
    this.utilizationForm = this.fb.group({
      remark: [null],
      expenditureInBirr: [null, Validators.required],
      expenditureDate: [null],
      budgetUuid: [null, Validators.required]
    })

    //subscribe to form change
    this.utilizationForm.valueChanges.subscribe((value) => {
      this.formChanged = this.formInitialValue ? Object.keys(this.formInitialValue)
        .some(key => this.utilizationForm.value[key] !==
          this.formInitialValue[key]) : true;
    });

  }


  get form() {
    return this.utilizationForm.controls;
  }

  set pageSize(pageSize: number) {
    this._set({ pageSize });
  }


  set searchTerm(searchTerm: string) {
    this._set({ searchTerm });
    this._search$.next();
  }

  set statusSearchTerm(statusSearchTerm: string) {
    this._set({ statusSearchTerm });
    this._search$.next();
  }

  private _set(patch: Partial<State>) {
    Object.assign(this._State, patch);
    this._search$.next();
  }

  get startIndex() {
    return this._State.startIndex;
  }

  get total$() {
    return this._State.totalRecords;
  }

  get endIndex() {
    return this._State.endIndex;
  }

  get totalRecords() {
    return this._State.totalRecords;
  }

  get page() {
    return this._State.page;
  }

  get pageSize() {
    return this._State.pageSize;
  }

  get searchTerm() {
    return this._State.searchTerm;
  }


  get statusSearchTerm() {
    return this._State.statusSearchTerm;
  }

  set page(page: number) {
    this._set({ page });
  }

  _search(): Observable<utilizationSearchResult> {

    const { page, searchTerm, statusSearchTerm } =
      this._State;
    // 1. sort
    let utilization = this.utilization;
    const total = utilization.length;
    // 3. paginate
    this._State.totalRecords = utilization.length;
    this._State.startIndex = (page - 1) * this.pageSize + 1;
    this._State.endIndex = (page - 1) * this.pageSize + this.pageSize;
    if (this.endIndex > this.totalRecords) {
      this._State.endIndex = this.totalRecords;
    }
    utilization = utilization.slice(this._State.startIndex - 1, this._State.endIndex);

    return of({ utilization, total });
  }

  onEdit(utilization: Utilization) {
    this.utilizationForUpdate = utilization;
    this.modalTitle = "Update Budget"
    this.isUpdate = true;
    this.utilizationForm.patchValue(utilization);
    this.form.expenditureDate.setValue(DateHelper.getDateFromString(utilization.expenditureDate))
    this.modalRef = this.modalService
      .open(this.utilizationModal, {
        scrollable: false,
        size: "md",
        centered: false,
      });
  }

  onDelete(uuid: string) {
    this._loaderService.showProgress.next(true);
    this.projectService.deleteUtilization(this.project.uuid, uuid).subscribe(
      () => {
        this._loaderService.showProgress.next(false);
        this.refreshEvent.emit();

      },
      (error) => {
        console.log(error);
        this._loaderService.showProgress.next(false);
        this.errorEvent.emit();

      }
    );

  }

  ngOnChanges(changes: SimpleChanges): void {
    this._search$.next();
  }

  onAddUtilization() {
    this.utilizationForm.reset()
    this.modalTitle = "Add Utilization"
    this.isUpdate = false;
    this.modalRef = this.modalService
      .open(this.utilizationModal, {
        scrollable: false,
        size: "md",
        centered: false,
      });
  }

  modalTitle: any;
  selectedBudget: Budget;

  validSubmit() {
    this.submit = true;
    if (this.utilizationForm.valid) {
      this.isUpdate ? this.updateUtilization() : this.submitUtilization();
    } else {
      console.log(this.utilizationForm.value)
    }

  }

  getUtilization(utilization: Utilization) {
    this._loaderService.showProgress.next(true);
    if (this.selectedBudget) {
      this.projectService.getBudgetUtilization(this.project.uuid, utilization.uuid)
        .subscribe(
          (response) => {
            this._loaderService.showProgress.next(false);
            this.utilization$.next(response);
          },
          (error) => {
            this.saving = false;
            this.errorEvent.emit(error);
            this._loaderService.showProgress.next(false);

          }
        );

    }
  }

  submitUtilization() {
    const formData = this.utilizationForm.value;
    this.saving = true;
    formData.expenditureDate = this.dateFormatter.format(this.form.expenditureDate.value)
    this._loaderService.showProgress.next(true);

    this.projectService.addUtilization(this.project.uuid, formData)
      .subscribe(
        () => {
          this._loaderService.showProgress.next(false);
          this.refreshEvent.emit();
          this.saving = false;
        },
        (error) => {
          this.saving = false;
          this.errorEvent.emit(error);
          this._loaderService.showProgress.next(false);
          this.saving = false;

        }
      );

  }

  updateUtilization() {
    const formData = this.utilizationForm.value;
    formData.expenditureDate = this.dateFormatter.format(this.form.expenditureDate.value)
    this.saving = true;
    this._loaderService.showProgress.next(true);
    this.projectService.updateUtilization(this.project.uuid, this.utilizationForUpdate.uuid, formData)
      .subscribe(
        () => {
          this._loaderService.showProgress.next(false);
          this.refreshEvent.emit();
          this.saving = false;
        },
        (error) => {
          this.saving = false;
          this.errorEvent.emit(error);
          this._loaderService.showProgress.next(false);
          this.saving = false;

        }
      );

  }

  onBudgetSelected($event) {
    if ($event !== undefined) {
      this.getUtilization($event);

    }
  }
}

