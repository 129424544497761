import {Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {Indicator, InterventionLogic, Project} from "../../../core/services/project/project";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {NgbActiveModal, NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {LoaderService} from "../../../core/services/shared/loader.service";
import {ProjectService} from "../../../core/services/project/project.service";

@Component({
  selector: 'app-indicators',
  templateUrl: './indicators.component.html',
  styleUrls: ['./indicators.component.scss']
})
export class IndicatorsComponent implements OnInit {

  @Input() indicators: Indicator[]
  @Input() enableAdd: boolean
  @Input() type: string
  @Input() project: Project
  @Input() logic: InterventionLogic
  @Output() refreshEvent: EventEmitter<any> = new EventEmitter<any>();
  @Output() errorEvent: EventEmitter<any> = new EventEmitter<any>();
  indicatorForm: FormGroup;
  modalTitle: any;
  submit: boolean;
  modalRef: any;
  saving: boolean;
  isUpdate: boolean;
  formChanged: any | boolean;
  formInitialValue: any;
  indicatorForUpdate: Indicator;
  @ViewChild("indicatorFormModal") indicatorModal: ElementRef;

  constructor(private fb: FormBuilder,
              private modalService: NgbModal,
              private _loaderService: LoaderService,
              private projectService: ProjectService,
              public activeModal: NgbActiveModal) {

  }

  ngOnInit(): void {
    this.indicatorForm = this.fb.group({
      name: [null, Validators.required],
      baseLine: [null],
      target: [null, Validators.required],
      achieved: [null],
      measurement: [null, Validators.required],
      indicatorType: [this.type],
      assumption: [null],
      code: [null, Validators.required],
      generalObjectiveUuid: [null],
      specificObjectiveUuid: [null],
      resultUuid: [null],
      activityUuid: [null]
    })

    //subscribe to form change
    this.indicatorForm.valueChanges.subscribe((value) => {
      this.formChanged = this.formInitialValue ? Object.keys(this.formInitialValue)
        .some(key => this.indicatorForm.value[key] !==
          this.formInitialValue[key]) : true;
    });

  }

  onEditIndicator(indicator: Indicator) {
    this.indicatorForUpdate = indicator;
    this.modalTitle = "Update Indicator"
    this.isUpdate = true;
    this.indicatorForm.patchValue(indicator);
    this.modalRef = this.modalService
      .open(this.indicatorModal, {
        scrollable: false,
        size: "md",
        centered: false,
      });
  }

  onDeleteIndicator(uuid: string) {
    this._loaderService.showProgress.next(true);
    this.projectService.deleteIndicator(this.indicatorForm.value, this.project.uuid, uuid)
      .subscribe(
        () => {
          this._loaderService.showProgress.next(false);
          this.refreshEvent.emit();
        },
        (error) => {
          this.saving = false;
          this.errorEvent.emit(error);
          this._loaderService.showProgress.next(false);

        }
      );
  }

  onAdd() {
    this.modalTitle = "Add Indicator"
    this.isUpdate = false;
    this.modalRef = this.modalService
      .open(this.indicatorModal, {
        scrollable: false,
        size: "md",
        centered: false,
      });
  }

  get form() {
    return this.indicatorForm.controls;
  }

  validSubmit() {
    this.submit = true;
    if (this.indicatorForm.valid) {
      this.isUpdate ? this.updateIndicator() : this.submitIndicator();
    }
  }

  setLogicParams() {
    switch (this.type) {

      case 'RESULT':
        this.form.resultUuid.setValue(this.logic.uuid);
        break;

      case 'GENERAL_OBJECTIVE':
        this.form.generalObjectiveUuid.setValue(this.logic.uuid);
        break;

      case 'SPECIFIC_OBJECTIVE':
        this.form.specificObjectiveUuid.setValue(this.logic.uuid);
        break;

      case 'ACTIVITY':
        this.form.activityUuid.setValue(this.logic.uuid);
        break;
    }
  }

  submitIndicator() {
    this.setLogicParams();
    this._loaderService.showProgress.next(true);
    this.projectService.addIndicator(this.indicatorForm.value, this.project.uuid)
      .subscribe(
        () => {
          this._loaderService.showProgress.next(false);
          this.refreshEvent.emit();
        },
        (error) => {
          this.saving = false;
          this.errorEvent.emit(error);
          this._loaderService.showProgress.next(false);

        }
      );

  }

  updateIndicator() {
    console.log(this.indicatorForUpdate);
    
    this._loaderService.showProgress.next(true);
    this.projectService.updateIndicator(this.indicatorForm.value, this.project.uuid, this.indicatorForUpdate.uuid)
      .subscribe(
        () => {
          this._loaderService.showProgress.next(false);
          this.refreshEvent.emit();
        },
        (error) => {
          this.saving = false;
          this.errorEvent.emit(error);
          this._loaderService.showProgress.next(false);

        }
      );

  }

}
