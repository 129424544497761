import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators,} from "@angular/forms";
import {Project} from 'app/core/services/project/project';
import {NgbDateCustomParserFormatter} from "../../../core/helpers/ngb-date-formatter";
import {Project_status, ProjectStatusList} from "../../../shared/models/Roles";
import {DateHelper} from "../../../core/helpers/Util";

@Component({
  selector: 'app-project-form',
  templateUrl: './project-form.component.html',
  styleUrls: ['./project-form.component.scss']
})
export class ProjectFormComponent implements OnInit {
  projectForm: FormGroup;
  formInitialValue: any;
  actionBtnName: string = "Add"
  formChanged: boolean = false;
  submit: boolean = false;
  saving: boolean = false;
  @Input() isUpdate: boolean;
  @Input() project: Project
  @Output() onSubmit: EventEmitter<Project> = new EventEmitter<Project>();
  projectStatus = ProjectStatusList

  constructor(private _fb: FormBuilder, private dateFormatter: NgbDateCustomParserFormatter) {
  }

  ngOnInit(): void {

    if (this.isUpdate) {
      this.actionBtnName = "Save"
    }

    this.projectForm = this._fb.group({

      name: new FormControl(null,
        {
          validators: [Validators.required],
          updateOn: 'change'

        }),
      dashboardId: new FormControl(null,
        {
          updateOn: 'change'

        }),
      dashboardPublicId: new FormControl(null,
        {
          updateOn: 'change'

        }),
      description: new FormControl(null,
        {
          validators: [Validators.required],
          updateOn: 'change'

        }),
      totalBudget: new FormControl(null, [Validators.required],
      ),
      status: new FormControl(Project_status.NOT_STARTED, {
        validators: [Validators.required],
        updateOn: 'change'
      }),
      startDate: new FormControl("", {
        validators: [Validators.required],
        updateOn: 'change'
      }),
      endDate: new FormControl("", {
        validators: [Validators.required],
        updateOn: 'change'
      })

    });

    //subscribe to form change
    this.projectForm.valueChanges.subscribe((value) => {

      if (this.formInitialValue) {

        const isFormChanged = Object.keys(this.formInitialValue).some(key => this.projectForm.value[key] !==
          this.formInitialValue[key]);

        this.formChanged = isFormChanged;
      } else {
        this.formChanged = true;
      }

    });
    this.prepareForm();
  }

  get form() {
    return this.projectForm.controls;
  }

  resetForm(form: FormGroup) {

    form.reset();
    Object.keys(form.controls).forEach(key => {
      form.get(key).setErrors(null);
    });
  }

  prepareForm(): void {
    if (this.isUpdate) {
      //patch form
      this.projectForm.patchValue(this.project)
      this.form.startDate.setValue(DateHelper.getDateFromString(this.project.startDate))
      this.form.endDate.setValue(DateHelper.getDateFromString(this.project.endDate))
      this.formInitialValue = this.projectForm.value;
      this.formChanged = false;
    }

  }




  validSubmit() {
    this.submit = true;
    if (this.projectForm.valid) {

      this.saving = true;
      this.projectForm.disable();
      let formD: Project = this.projectForm.value;
      const startDate: any = formD.startDate;
      const endDate: any = formD.endDate;
      formD.startDate = this.dateFormatter.format(startDate)
      formD.endDate = this.dateFormatter.format(endDate)
      this.onSubmit.emit(formD);

    } else {
      console.log(this.projectForm.errors)
    }

  }

}
