<div class="account-pages my-5 pt-sm-5">
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-md-8 col-lg-6 col-xl-5">
        <div class="card  rounded-3  overflow-hidden">
          <div class="bg-soft bg-primary">
            <div class="text-center text-primary p-2">
              <h5 class="text-primary">Welcome Back !</h5>
              <p>Sign in to continue to PDTS.</p>
            </div>
          </div>
          <div class="card-body mt-0">

            <div class="p-2">
              <form (ngSubmit)="onSubmit()" [formGroup]="loginForm" class="form-horizontal">

                <ngb-alert *ngIf="error" [dismissible]="false" type="warning">{{ error }}</ngb-alert>

                <div class="mb-3">
                  <label >User Name</label>
                  <input
                    [ngClass]="{ 'is-invalid': submitted && f.username.errors }" class="form-control"
                    formControlName="username"  placeholder="User Name"
                    type="email"/>
                  <div *ngIf="submitted && f.username.errors" class="invalid-feedback">
                    <div *ngIf="f.username.errors.required">User Name is required</div>
                  </div>
                </div>

                <div class="mb-3">
                  <label>Password</label>
                  <div class="input-group auth-pass-inputgroup">
                    <input
                      [ngClass]="{ 'is-invalid': submitted && f.password.errors }"
                      [type]="visiblePassword ? 'text' :'password'"
                      aria-describedby="password-addon"
                      aria-label="Password"
                      class="form-control" formControlName="password"
                      placeholder="Enter password">

                    <button (click)="togglePasswordField()"
                            class="btn btn-light ms-0"
                            id="password-addon"
                            type="button">
                      <i
                        [ngClass]=" visiblePassword ? 'mdi mdi-eye-off': 'mdi mdi-eye-outline'
                        "></i>
                    </button>
                    <div *ngIf="submitted && f.password.errors" class="invalid-feedback">
                      <span *ngIf="f.password.errors.required">Password is required</span>
                    </div>
                  </div>
                </div>

                <div class="form-check">
                  <input class="form-check-input" id="remember-check" type="checkbox">
                  <label class="form-check-label" for="remember-check">
                    Remember me
                  </label>
                </div>

                <div class="mt-3 d-grid">
                  <button [disabled]="loading" class="btn btn-primary" type="submit">Log In
                    <span *ngIf="loading" aria-hidden="true" class="spinner-border spinner-border-sm"
                          role="status"></span>
                  </button>
                </div>


              </form>
            </div>

          </div>
        </div>
        <div class="mt-2 text-center">
          <p>© {{year}} PDTS. </p>
        </div>

      </div>
    </div>
  </div>
</div>

