import {MenuItem} from './menu.model';


const ADMIN_NAV_ITEMS: MenuItem[] =
  [
    {

      id: 1,
      label: 'Dashboards',
      icon: 'mdi mdi-speedometer',
      link: '/dashboard/admin'
    },

    {
      id: 8,
      label: 'MODULES',
      isTitle: true
    },
    {
      id: 9,
      label: 'Projects',
      icon: 'mdi mdi-clipboard-list-outline',
      link: '/projects'
    },


    {
      id: 24,
      label: 'Settings',
      isTitle: true
    },

    {
      id: 26,
      label: 'Users',
      icon: 'dripicons-user-group',
      link: "/users"
    },
    {
      id: 28,
      label: 'System Settings',
      icon: 'dripicons-toggles',
      link: "/settings"
    },


  ];

const PROJECT_NAV_ITEMS: MenuItem[] =
  [
    {

      id: 1,
      label: 'Dashboards',
      icon: 'mdi mdi-speedometer',
      link: '/dashboard/project'
    },

    {
      id: 8,
      label: 'MODULES',
      isTitle: true
    },
    {
      id: 9,
      label: 'Projects',
      icon: 'mdi mdi-clipboard-list-outline',
      link: '/projects'
    },


    {
      id: 24,
      label: 'Settings',
      isTitle: true
    },


    {
      id: 28,
      label: 'System Settings',
      icon: 'dripicons-toggles',
      link: ""
    },


  ];


export {ADMIN_NAV_ITEMS, PROJECT_NAV_ITEMS}

