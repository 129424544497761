<div class="container-boxed col-sm-6 mx-auto">

    <div class="page-title-box d-flex  justify-content-between">
        <div class="col-sm-4">
            <app-page-title title="SYSTEM SETTINGS">
            </app-page-title>
        </div>

    </div>
    <ngb-alert #selfClosingAlert (closed)="message = ''" *ngIf="message" [dismissible]="false" [type]="alertType">{{
        message
        }}
    </ngb-alert>

    <div class="card">

        <div class="card-body">

            <ul #nav="ngbNav" [activeId]="1" class="nav-tabs nav-tabs-custom" ngbNav>

                <li [destroyOnHide]="false" [ngbNavItem]="1">
                    <a ngbNavLink>Poject Settings</a>
                    <ng-template ngbNavContent>

                        <div class="col-sm-6">
                            <form (ngSubmit)="submitSettings()" [formGroup]="settingsForm" class="needs-validation">
                                <div class="col-sm">
                                    <div class="mb-3  mt-3">
                                        <label>USD exchange rate <span class="text-danger"> *</span></label>
                                        <input formControlName="rate" class="form-control"
                                            placeholder="Enter exchange rate">
                                    </div>
                                    <div class="mb-3  mt-3">
                                        <label>Annual Evaluation Reminder Expression <span class="text-danger">
                                                *</span></label>
                                        <input formControlName="annualEvaluationReminderChronExpression"
                                            class="form-control" placeholder="enter expresion">
                                    </div>
                                    <div class="mb-3  mt-3">
                                        <label>Semi Annual Evaluation Reminder Expression <span class="text-danger">
                                                *</span></label>
                                        <input formControlName="halfYearEvaluationReminderChronExpression"
                                            class="form-control" placeholder="enter expresion">
                                    </div>
                                    <div class="mb-3  mt-3">
                                        <label>Probation Period Reminder Expression <span class="text-danger">
                                                *</span></label>
                                        <input formControlName="probationPeriodReminder" class="form-control"
                                            placeholder="enter expresion">
                                    </div>

                                </div>

                                <div class="d-flex flex-wrap gap-2">
                                    <button class="btn btn-primary" type="submit">Save

                                    </button>
                                    <button class="btn btn-secondary waves-effect" type="reset">
                                        Reset
                                    </button>
                                </div>

                            </form>
                        </div>
                    </ng-template>
                </li>


            </ul>
            <div [ngbNavOutlet]="nav" class="p-3"></div>

        </div>
    </div>
</div>